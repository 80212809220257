<script lang="ts" setup>
const props = defineProps<{ data: { title: string, items: Array<{ icon: string, title: string, link: string }> } }>()
</script>

<template>
	<div class="act_w1030 act_pt-1 act_pb-3">
		<h3 class="playfair act_txt30 act_no-marg txt-center pb-2">
			{{ props.data.title }}
		</h3>
		<div
			class="flex gap-1 linkBlocks flex-wrap justify-content-center"
			:class="{ 'four-items': props.data.items?.length === 4 }"
		>
			<a
				v-for="item in props.data.items"
				:key="item.title"
				:href="item.link"
				class="hover act_flex act_gap-2 decoration-none bg-gray-20 p-1-5 radius-2"
			>
				<img :src="item.icon" :alt="item.title">
				<p class="act_no-marg">
					{{ item.title }}
				</p>
			</a>
		</div>
	</div>
</template>

<style scoped>
.linkBlocks>a{
	width: 300px;
	flex: 0 0 300px;
}
.four-items {
	max-width: 800px;
	margin-inline: auto;
}
</style>
