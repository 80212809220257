<script setup lang="ts">import { ref as _ref, computed as _computed } from 'vue';

import { onMounted, ref, watch } from 'vue'
import { BglField } from '@bagelink/vue'
import { useStore } from '@/store'

type OptionValue = string | string[] | undefined

type Option = {
	name: string
	value?: string
	image?: string
} | string

const props = defineProps<{
	title?: string
	subtitle?: string
	id: string
	hidename?: boolean
	slots?: any[]
	asArray?: boolean
	options: Option[]
	// modelValue?: OptionValue
}>()

// const emit = defineEmits(['update:modelValue'])
const store = useStore()
const slotField = _ref<Record<string, any>>({})

const optionToImage = (option: Option) => (typeof option === 'string' ? '' : option.image)
const optionToName = (option: Option) => (typeof option === 'string' ? option : option.name)
function optionToValue(option: Option) {
	if (typeof option === 'string') return option
	if (option.value === undefined) return option.name
	return option.value
}

const model = defineModel<OptionValue>()
const currentVal = _computed(() => model.value)

function isEqual(option: Option) {
	const value = optionToValue(option)
	if (currentVal.value && Array.isArray(currentVal.value)) return currentVal.value.includes(value)
	return model.value === value as OptionValue
}

watch(() => slotField.value, () => {
	if (props.id && slotField.value[props.id]) model.value = `${slotField.value[props.id]}`
}, { deep: true })

function selectOption(option: Option) {
	const storeFormItem = store.form.item // ! HACK for some reason the form item is being reset to an empty string after emitting

	const value = optionToValue(option)

	if (props.asArray) model.value = [value]
	else model.value = value

	store.form.item = storeFormItem // ! HACK for some reason the form item is being reset to an empty string after emitting
	store.nextStep(props.id)
}

// onMounted(() => model.value = props.modelValue)
</script>

<template>
	<div class="wiz-options-wrap act_flex act_gap-2 wrap justify-content-center">
		<BglField v-for="(slot, i) in slots" :key="i" v-model="slotField" :field="slot" />
		<div
			v-for="opt in options"
			:key="optionToName(opt)"
			class="wiz-option hover"
			:class="{ wizOptionActive: isEqual(opt) }"
			@click="selectOption(opt)"
		>
			<img
				v-if="optionToImage(opt)"
				:src="optionToImage(opt)"
				:alt="optionToName(opt)"
			>
			<p v-if="!hidename || !optionToImage(opt)">
				{{ $t(optionToName(opt)) }}
			</p>
		</div>
	</div>
</template>

<style>
@media screen and (max-width: 900px) {
  .wiz-options-wrap.act_flex.act_gap-2.wrap.justify-content-center {
    gap: 0.25rem;
  }

  .form-title {
    margin-top: 0;
    margin-bottom: 0.5rem;
  }
}
</style>
