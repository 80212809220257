import type { RouterScrollBehavior } from 'vue-router'

import { useHead } from '@unhead/vue'
import siteMap from '@/data/siteMap.json'
import Home from '@/views/Home.vue'
// import type { Component } from 'vue';
import { $tdb, type Locale, availableLangs, i18n, localizer, translatePath, noAltRoutes } from '@/lang'
import pageRouter from '@/data/pageRouter.json'
import NotFound from '@/views/404.vue'
import Blocks from '@/views/Blocks.vue'
import BlocksPreview from '@/views/BlocksPreview.vue'

const comps: { [key: string]: any } = {
	Home,
	'404': NotFound,
	Blocks,
	'About Us': async () => import('@/views/AboutUs.vue'),
	'Thank You': async () => import('@/views/ThankYou.vue'),
	'No Thank You': async () => import('@/views/NoThankYou.vue'),
	'Get The Best Offer': async () => import('@/views/Sell.vue'),
	'Recently Sold Index': async () => import('@/views/RecentlySoldIndex.vue'),
	'Valuate Diamond': async () => import('@/views/ValuateDiamond.vue'),
	'Our Services': async () => import('@/views/OurServices.vue'),
	'How Take Picture': async () => import('@/views/HowTakePicture.vue'),
	'Contact Us': async () => import('@/views/ContactUs.vue'),
	'Why Sell With Us': async () => import('@/views/WhySellWithUs.vue'),
	'What We Accept': async () => import('@/views/WhatWeAccept.vue'),
	'How It Works': async () => import('@/views/HowItWorks.vue'),
	'Blog Index': async () => import('@/views/BlogIndex.vue'),
	'Blog Category': async () => import('@/views/BlogCategory.vue'),
	'Blog Page': async () => import('@/views/BlogPage.vue'),
	'Sell Safely': async () => import('@/views/SellingSafelyOnline.vue'),
	'Sell Online': async () => import('@/views/SellOnline.vue'),
	'Legal Page': async () => import('@/views/LegalPage.vue'),
	'Sold Item': async () => import('@/views/SoldItem.vue'),
	'Event Index': async () => import('@/views/EventIndex.vue'),
	'Event Page': async () => import('@/views/EventPage.vue'),
	'Packing': async () => import('@/views/Packing.vue'),
}

export function routes() {
	return [
		{
			path: '/',
			name: 'root',
			component: Home,
			meta: { alt: { it: '/it', fr: '/fr', es: '/es', en: '/' } },
		},
		{
			path: '/block-preview/:pathMatch(.*)*',
			component: BlocksPreview,
		},
		...pageRouter.map(({ path, comp, name, alt }) => {
			return {
				path,
				name,
				component: comps[comp],
				meta: { alt }
			}
		}),
		{
			path: '/:pathMatch(.*)*',
			redirect: () => '/404'
			,
		},
	]
}

export const scrollBehavior: RouterScrollBehavior = (to, from, savedPosition) => {
	// const toLang = to.path.split('/')[0];
	// const fromLang = to.path.split('/')[0];
	const fromPath = from.path.split('/').slice(1).join('/')
	const toPath = to.path.split('/').slice(1).join('/')
	if (fromPath === toPath) return
	if (to.hash) {
		return {
			behavior: 'smooth',
			el: to.hash,
			offset: { top: 0, left: 0 },
		}
	}
	if (savedPosition) return savedPosition
	return { top: 0, left: 0 }
}

export function beforeEach(to: any, from: any, next: any) {
	const page = siteMap.find(p => p.path === to.path)
	let lang = to.path.split('/').filter(Boolean).shift()
	lang = availableLangs.includes(lang as Locale) ? lang : 'en'
	const locale = (i18n.locale as any).value
	if (lang !== locale) i18n.locale.value = lang as Locale
	headerFunction(page, to, lang)
	next()
}


function headerFunction(page: Record<string, any> = {}, to: any, lang: string) {
	const canonical = [{
		rel: 'canonical',
		href: `https://auctentic.com${to.path}`,
	}]
	const script = []
	if (page.schema) {
		script.push({
			type: 'application/ld+json',
			innerHTML: JSON.stringify(page.schema),
		})
	}

	const hreflangs = []
	if (!noAltRoutes.some((r: string) => to.path.match(r))) {
		for (const hreflang of availableLangs) {
			const altRoute = translatePath(to.path, hreflang)
			const href = localizer(altRoute, hreflang)
			const obj = {
				rel: 'alternate',
				hreflang: hreflang === 'en' ? 'x-default' : hreflang,
				href: `https://auctentic.com${href}`,
			}
			hreflangs.push(obj)
		}
	}

	const meta = [
		{ name: 'title', content: $tdb(page, 'metaTitle', lang) },
		{ name: 'description', content: $tdb(page, 'metaDescription', lang) },
		{ name: 'og:image', content: page.ogImage },
		{ name: 'og:title', content: $tdb(page, 'metaTitle', lang) },
		{ name: 'og:description', content: $tdb(page, 'metaDescription', lang) },
		{ name: 'twitter:card', content: 'summary' },
		{ name: 'twitter:title', content: $tdb(page, 'metaTitle', lang) },
		{ name: 'twitter:description', content: $tdb(page, 'metaDescription', lang) },
		{ name: 'twitter:image', content: page.ogImage },
	]

	useHead({
		htmlAttrs: { lang },
		title: page.metaTitle,
		script,
		link: [...hreflangs, ...canonical],
		meta,
	})
}
