import { $t } from '../lang'

export const diamondShapes = [
	{ name: 'form.Round', image: '/img/diamond-shape-round-extra.svg', value: 'Round' },
	{ name: 'form.Princess', image: '/img/diamond-shape-princess-extra.svg', value: 'Princess' },
	{ name: 'form.Cushion', image: '/img/diamond-shape-cushion-extra.svg', value: 'Cushion' },
	{ name: 'form.Marquise', image: '/img/diamond-shape-marquise-extra.svg', value: 'Marquise' },
	{ name: 'form.Emerald', image: '/img/diamond-shape-Emerald-extra.svg', value: 'Emerald' },
	{ name: 'form.Oval', image: '/img/diamond-shape-Oval-extra.svg', value: 'Oval' },
	{ name: 'form.Pear', image: '/img/diamond-shape-Pear-extra.svg', value: 'Pear' },
	{ name: 'form.Heart', image: '/img/diamond-shape-Heart-extra.svg', value: 'Heart' },
	{ name: 'form.Radiant', image: '/img/diamond-shape-Radiant-extra.svg', value: 'Radiant' },
	{ name: 'form.notsureother', value: 'Other' }
]

export const diamondCaratRanges = [
	'0-0.49 ct.',
	'0.5-0.89 ct.',
	'0.9-0.99 ct.',
	'1-1.49 ct.',
	'1.5-1.99 ct.',
	'2-2.99 ct.',
	'3-3.99 ct.',
	'4-4.99 ct.',
	'5+ ct.',
	{ name: 'form.notSure', value: 'Other' },
]

export const gemstoneCaratRanges = [
	'0-1.49 ct.',
	'1.5-1.99 ct.',
	'2-2.99 ct.',
	'3-3.99 ct.',
	'4-4.99 ct.',
	'5+ ct.',
	{ name: 'form.notSure', value: 'Other' },
]

export const diamondColors = [
	'D',
	'E',
	'F',
	'G',
	'H',
	'I',
	'J',
	'K',
	'L',
	'M',
	{ name: 'form.notSureOther', value: 'Other' },
]

export const diamondClarities = [
	'IF',
	'VVS1',
	'VVS2',
	'VS1',
	'VS2',
	'SI1',
	'SI2',
	'I1',
	{ name: 'form.notSureOther', value: 'Other' },
]

// const clarityList = ['IF', 'VVS1', 'VVS2', 'VS1', 'VS2', 'SI1', 'SI2', 'SI3', 'I1'] // I2, I3

export const diamondCertificateTypes = [
	'GIA',
	'IGI',
	'HRD',
	'LFG',
	'IGE',
	'AIG',
	'GemReport Antwerp (gra)',
	'GwLab',
	'ALGT',
	{ name: 'form.notSureOther', value: 'Other' },
]

export const gemstoneCertificateTypes = [
	'SSEF',
	'GUBELIN',
	'GRS',
	'AGL',
	'ALGT',
	{ name: 'form.notSureOther', value: 'Other' },
]

export const gemstoneShapes = [
	{ name: $t('form.oval'), image: '/img/diamond-shape-Oval-extra.svg', value: 'Oval' },
	{ name: $t('form.cushion'), image: '/img/diamond-shape-cushion-extra.svg', value: 'Cushion' },
	{ name: $t('form.emerald'), image: '/img/diamond-shape-Emerald-extra.svg', value: 'Emerald' },
	{ name: $t('form.pear'), image: '/img/diamond-shape-Pear-extra.svg', value: 'Pear' },
	{ name: $t('form.heart'), image: '/img/diamond-shape-Heart-extra.svg', value: 'Heart' },
	{ name: $t('form.round'), image: '/img/diamond-shape-round-extra.svg', value: 'Round' },
	{ name: $t('form.notSureOther'), value: 'Other' },
]
