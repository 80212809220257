<script lang="ts" setup>
function reloadWindow() {
	window.location.reload()
}
</script>

<template>
	<div class="act_center act_w770 act_pt-4">
		<img class="" width="100" src="../assets/Wizard-Diamonds.svg" alt="Diamond">
		<!-- <p class="mini-title">{{ $t("unqualified.title") }}</p> -->
		<h1 class="act_txt60 playfair balance act_no-marg act_pt-1 act_pb-2">
			{{ $t('unqualified.trust') }}
		</h1>
		<p class="act_pb-2">
			{{ $t('unqualified.paragraph') }}
		</p>
		<p class="act_pb-2">
			{{ $t('unqualified.questions') }}
		</p>
		<div class="act_flex act_gap-1 wrap justify-content-center">
			<p class="act_btn w240" to="/get-the-best-offer" @click="reloadWindow">
				{{ $t('unqualified.differentItem') }}
			</p>
			<LocalLink class="act_btn w240" to="/what-you-can-sell">
				{{ $t('unqualified.whatWeSell') }}
			</LocalLink>
		</div>
	</div>
</template>
