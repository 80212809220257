export function timeAgo(date: Date | string): string {
	if (typeof date === 'string') date = new Date(date)
	const intervals = [
		{ seconds: 31536000, label: 'year' },
		{ seconds: 2592000, label: 'month' },
		{ seconds: 86400, label: 'day' },
		{ seconds: 3600, label: 'hour' },
		{ seconds: 60, label: 'minute' },
		{ seconds: 1, label: 'second' },
	]

	const now = new Date()
	const secondsElapsed = Math.floor((now.getTime() - date.getTime()) / 1000)

	for (const interval of intervals) {
		const count = Math.floor(secondsElapsed / interval.seconds)
		if (count >= 1) {
			return count === 1 ? `1 ${interval.label} ago` : `${count} ${interval.label}s ago`
		}
	}
	return 'Just now'
}
