<script setup lang="ts">import { ref as _ref, computed as _computed } from 'vue';

import { useI18n } from 'vue-i18n'
import { useRoute, useRouter } from 'vue-router'
import { useWindowScroll } from '@vueuse/core'
import sellOnlineLinks from '@/data/sellOnlineLinks.json'
import { Flag } from '@bagelink/vue'
import NavLinks from '@/components/NavLinks.vue'
import {
	languages,
	setLang,
	setLangURL,
	useLangTools,
} from '@/lang'
import { settings } from '@/data/settings'
import { events } from '@/data/events'
import { watch } from 'vue'

const { y } = useWindowScroll()
let prevScrollPos = 0

const $i18n = useI18n()
const route = useRoute()
const router = useRouter()

const { $tdb } = useLangTools(router)

const opennavbar = _ref(false)
const openMenuDropdown = _ref(false)
const openCountryDropdown = _ref(false)

const selectedCountry = _ref('All')
const tabCountries = _computed(() => {
	const _countries = new Set<string>().add('All')
	events.forEach((event) => {
		const country = $tdb(event, 'country')
		if (country) {
			_countries.add(country)
		}
	})
	return Array.from(_countries)
})

watch(()=>selectedCountry.value, (newCountry) => {
	console.log('Country selected:', newCountry)
})

const smallNav = _computed(() => {
	const scrollingUp = y.value < prevScrollPos
	prevScrollPos = y.value
	return scrollingUp && y.value > 200
})

function scrollUp() { window.scrollTo({ top: 0, behavior: 'smooth' }) }
const showScrollUp = _computed(() => y.value > 200)
</script>

<template>
	<div class="top-nav-wrapper">
		<NavLinks @opennavbar="$event => opennavbar = $event" />
	</div>
	<div class="hiddenNav" :class="{ smallNav }">
		<NavLinks @opennavbar="$event => opennavbar = $event" />
	</div>
	<div class="menu" :class="{ opennavbar }">
		<LocalLink class="relative" to="/" title="Auctentic">
			<img loading="lazy" width="160" height="35.27" src="../assets/logo-black.png" alt="AUCTENTIC">
		</LocalLink>
		<div class="close-menu" @click="opennavbar = false">
			<img loading="lazy" width="19" height="19" src="../assets/close-blue.svg" alt="close">
		</div>

		<div class="playfair act_black menu-links-wrap">
			<div class="lang-picker in-menu">
				<RouterLink
					v-for="lang in languages" :key="lang.locale" :to="setLangURL(route.path, lang.locale)" class="lang hover" :class="{ activeLang: $i18n.locale.value === lang.locale }"
					@click="setLang(lang.locale)"
				>
					<Flag :country="(lang.flag as any)" /> {{ lang.locale }}
				</RouterLink>
			</div>
			<LocalLink class="menu-link act_txt24" to="/how-does-it-work" :title="$t('home.howItWorks')" :class="{ activenav: route.path.includes('how-does-it-work') }">
				{{ $t("home.howItWorks") }}
			</LocalLink>

			<div class="menu-link act_txt24" :title="$t('general.selling')" :class="{ activenav: route.path.includes('selling'), openMenuDropdown }">
				<div class="menu-drop" @click="openMenuDropdown = !openMenuDropdown">
					<div class="menu-drop-title">
						{{ $t("general.selling") }}
						<img width="8" height="8" src="../assets/down-arrow-blue.svg" alt="">
					</div>
					<div class="menu-drop-box">
						<LocalLink v-for=" sell in sellOnlineLinks " :key="sell.slugEN" :title="$tdb(sell, 'name')" class=" decoration-none block light hover act_pb-025" :to="`${$tdb(sell, 'slug')}`">
							<p>
								{{ $tdb(sell, 'name') }}
							</p>
						</LocalLink>
					</div>
				</div>
			</div>

			<LocalLink class="menu-link act_txt24" to="/what-you-can-sell" :title="$t('whatWeAccept.whatWeAccept')" :class="{ activenav: route.path.includes('what-you-can-sell') }">
				{{ $t("whatWeAccept.whatWeAccept") }}
			</LocalLink>
			<!-- <LocalLink class="menu-link act_txt24" to="/valuate-diamond" :title="$t('home.valuateYourDiamond')" :class="{ activenav: route.path.includes('valuate-diamond') }">
				{{ $t("home.valuateYourDiamond") }}
				<img height="20" class="act_mb-025" src="@/assets/calculator-small.svg" :alt="$t('home.valuateYourDiamond')">
			</LocalLink> -->
			<!-- <LocalLink class="menu-link act_txt24" to="/event" :title="$t('general.events')" :class="{ activenav: route.path.includes('event') }">
				{{ $t("general.events") }}
			</LocalLink> -->

			<div class="menu-link act_txt24" :title="$t('general.events')" :class="{ activenav: route.path.includes('events'), openCountryDropdown }">
				<div class="menu-drop" @click="openCountryDropdown = !openCountryDropdown">
					<div class="menu-drop-title">
						{{ $t("general.events") }}
						<img width="8" height="8" src="../assets/down-arrow-blue.svg" alt="rrow">
					</div>
					<div class="menu-drop-box">
						<LocalLink
							v-for="country in tabCountries"
							:key="country"
							class="decoration-none block light hover act_pb-025"
							:to="`/event?tab=${country}`"
							@click="selectedCountry = country"
						>
							<p>{{ country === 'All' ? $t('productTypes.All') : country }}</p>
						</LocalLink>
					</div>
				</div>
			</div>

			<LocalLink class="menu-link act_txt24" to="/recently-sold" :title="$t('recentlySold.title')" :class="{ activenav: route.path.includes('recently-sold') }">
				{{ $t("recentlySold.title") }}
			</LocalLink>
			<LocalLink class="menu-link act_txt24" to="/about-us" :title="$t('about.aboutUs')" :class="{ activenav: route.path.includes('about-us') && !route.hash.includes('FAQ') }">
				{{ $t("about.aboutUs") }}
			</LocalLink>
			<LocalLink class="menu-link act_txt24" to="/contact-us" :title="$t('contactUs.contactUs')" :class="{ activenav: route.path.includes('contact-us') }">
				{{ $t("contactUs.contactUs") }}
			</LocalLink>
			<LocalLink class="menu-link act_txt24" to="/blog" :title="$t('general.blog')" :class="{ activenav: route.path.includes('blog') }">
				{{ $t("general.blog") }}
			</LocalLink>
			<LocalLink class="menu-link act_txt24" to="/about-us#faq" :title="$t('about.FAQ')" :class="{ activenav: route.hash.includes('FAQ') }">
				{{ $t("about.FAQ") }}
			</LocalLink>
			<!-- <LocalLink
				class="menu-link act_txt24" to="/how-to-take-picture" :title="$t('general.howToTakeQualityPhotos')"
				:class="{ activenav: route.path.includes('how-to-take-picture') }"
			>
				{{ $t("general.howToTakeQualityPhotos") }}
			</LocalLink> -->

			<a class="menu-link act_txt24" href="https://app.auctentic.com/" target="_blank" title="Login">
				{{ $t("general.login") }}
			</a>
		</div>
		<div>
			<LocalLink to="/get-the-best-offer" class="act_btn" :title="$t('cta.getTheBestOffer')">
				{{ $t("cta.getTheBestOffer") }}
			</LocalLink>
			<div class="act_flex act_gap-2 act_pt-1">
				<a class="menu-soc hover" :href="$tdb(settings[0], 'facebook')" target="_blank">
					<img loading="lazy" width="31" height="31" src="../assets/facebook-blue.svg" alt="facebook"></a>

				<a class="menu-soc hover" :href="$tdb(settings[0], 'instagram')" target="_blank">
					<img loading="lazy" width="31" height="31" src="../assets/instagram-blue.svg" alt="instagram"></a>

				<a class="menu-soc hover" :href="$tdb(settings[0], 'whatsapp')" target="_blank">
					<img loading="lazy" width="31" height="31" src="../assets/whatsapp-blue.svg" alt="whatsapp"></a>

				<a class="menu-soc hover" :href="$tdb(settings[0], 'youtube')" target="_blank">
					<img loading="lazy" width="31" height="31" src="../assets/youtube-blue.svg" alt="youtube"></a>

			<!-- <a class="menu-soc hover" href="" target="_blank">
					<img width="31" height="31" src="../assets/phone-blue.svg" alt="phone"></a> -->
			</div>
		</div>
	</div>
	<div class="scroll-up" :class="{ show: showScrollUp }" @click="scrollUp">
		<!-- ⌃1 -->
		<img width="24" height="40" src="../assets/up.svg" alt="up">
	</div>
</template>

<style>
.menu-drop-title:hover {
	color: var(--blue);
}

.menu-drop {
	cursor: pointer;
	/* padding-bottom: 0.5rem; */
}

.menu-drop:hover {
	color: var(--black);
}

.menu-drop p {
	line-height: 1.6;
	font-size: 16px;
	font-family: 'Open Sans', sans-serif;
}

.menu-drop p:hover {
	color: var(--blue);
}

.menu-drop-box {
	max-height: 0;
	overflow: hidden;
	transition: all 200ms ease;
	background: #e8e8e8;
	padding-inline: 1rem;
	border-radius: 10px;
}

.openMenuDropdown .menu-drop-box, .openCountryDropdown .menu-drop-box {
	max-height: 100vh;
	transition: all 200ms ease;
	padding-block: 0.5rem;
}

.openMenuDropdown .menu-drop img, .openCountryDropdown .menu-drop img {
	transform: rotate(180deg);
}

.menu-drop img {
	transition: all 400ms ease;

}

.menu-links-wrap {
	overflow-y: auto;
	padding-top: 1rem;
	padding-bottom: 1rem;

}

.scroll-up {
	position: fixed;
	bottom: 20px;
	right: 20px;
	z-index: 1000;
	cursor: pointer;
	background-color: rgba(255, 255, 255, 0.25);
	height: 40px;
	width: 40px;
	border-radius: 100%;
	text-align: center;
	line-height: 45px;
	transform: scale(0);
	transition: all 0.3s ease;
	display: flex;
	justify-content: center;
	justify-items: center;
}

.scroll-up img {
	width: 24px;
}

.scroll-up.show {
	transform: scale(1);
}

.hiddenNav {
	position: fixed;
	left: 0;
	right: 0;
	top: 0;
	z-index: 99;
	transform: translateY(-100px);
	opacity: 1;
	transition: all 400ms ease;
}

.hiddenNav .logo-wrap p {
	display: none;
}

.hiddenNav .topnav {
	padding: 1.2rem 1rem;
	background: var(--blue-dark);
	box-shadow: 0 0 30px rgba(0, 0, 0, 0.256);
	width: 100%;
	max-width: 100%;
}

.smallNav {
	transform: translateY(0px);
}

.hiddenNav .logo-wrap {
	transform: scale(0.8);
}

.small-top-nav-btn {
	display: block;
	padding: 0.5rem 1rem;
	border-radius: 100px;
	font-size: 14px;
	text-transform: uppercase;
	background: var(--blue);
	text-decoration: none;
}

.home-page .small-top-nav-btn {
	display: none;
}

.hiddenNav .small-top-nav-btn {
	display: block;
	padding: 0.5rem 1rem;
	border-radius: 100px;
	font-size: 14px;
	text-transform: uppercase;
	background: var(--blue);
	text-decoration: none;
}

.top-nav-wrapper {
	z-index: 9;
	position: relative;
}
@media screen and (max-width: 900px) {
	.small-top-nav-btn {
		font-size: 13px;
		margin-top: -6px;
		gap: 1rem;
		margin-inline-start: auto;
	}

	.hiddenNav.smallNav .menu {
		top: 0px !important;
		bottom: 0 !important;
	}

	.hiddenNav .menu {
		top: 100px !important;
	}
}

.activenav {
	color: var(--blue);
}
</style>
