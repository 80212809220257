<script setup lang="ts">import { ref as _ref } from 'vue';

import { useRouter } from 'vue-router'
import { useLangTools } from '@/lang'

import sellOnlineLinks from '@/data/sellOnlineLinks.json'
import legalLinks from '@/data/legalLinks.json'
import { offices } from '@/data/offices'
import { settings } from '@/data/settings'

const { $tdb } = useLangTools(useRouter())
let openFooter = _ref('')

const toggleFooter = (key: string) => (openFooter.value = openFooter.value !== key ? key : '')
</script>

<template>
	<footer class="footer-wrap">
		<div class="footer act_w1170">
			<div class="act_w100">
				<LocalLink to="/" class="footer-logo hover" title="Auctentic">
					<img loading="lazy" height="50.5" width="230" src="../assets/logo.png" alt="Auctentic">
				</LocalLink>
				<div class="footer-locations">
					<div v-for="office in offices" :key="office._id">
						<p>
							<b class="">
								{{ $tdb(office, 'name') }}
							</b>
						</p>
						<div v-if="!office.google">
							{{ $tdb(office, 'address') }}
						</div>
						<div v-if="office.google">
							<a :href="office.google" target="_blank">{{ $tdb(office, 'address') }}</a>
						</div>
						{{ office.phone }}
					</div>
				</div>
			</div>
			<div class="footer-line" />
			<div class="footer-links">
				<div class="" :class="{ openFooter: openFooter === 'General' }">
					<div class="footer-title" @click="toggleFooter('General')">
						<p>{{ $t('footer.general') }}</p>
						<img loading="lazy" src="../assets/down-arrow.svg" alt="down">
					</div>
					<div class="footer-body">
						<LocalLink
							class="decoration-none block light hover"
							to="/how-does-it-work"
							:title="$t('footer.howItWorks')"
						>
							{{ $t('footer.howItWorks') }}
						</LocalLink>
						<LocalLink
							class="decoration-none block light hover"
							to="/why-sell-with-us"
							:title="$t('footer.whySellWithUs')"
						>
							{{ $t('footer.whySellWithUs') }}
						</LocalLink>
						<LocalLink
							class="decoration-none block light hover"
							to="/event"
							:title="$t('general.events')"
						>
							{{ $t("general.events") }}
						</LocalLink>
						<LocalLink
							class="decoration-none block light hover"
							to="/blog"
							:title="$t('footer.blog')"
						>
							{{ $t('footer.blog') }}
						</LocalLink>
						<!-- <LocalLink
							class="decoration-none block light hover calculator"
							to="/valuate-diamond"
							:title="$t('home.valuateYourDiamond')"
						>
							{{ $t('home.valuateYourDiamond') }}
						</LocalLink> -->
						<LocalLink
							class="decoration-none block light hover"
							to="/contact-us"
							:title="$t('footer.contactUs')"
						>
							{{ $t('footer.contactUs') }}
						</LocalLink>
						<LocalLink
							class="decoration-none block light hover"
							to="/what-you-can-sell"
							:title="$t('footer.whatWeAccept')"
						>
							{{ $t('footer.whatWeAccept') }}
						</LocalLink>
						<LocalLink
							class="decoration-none block light hover"
							to="/our-services"
							:title="$t('footer.ourServices')"
						>
							{{ $t('general.ourServices') }}
						</LocalLink>
						<LocalLink
							class="decoration-none block light hover"
							to="/sell-safely"
							:title="$t('footer.sellSafely')"
						>
							{{ $t('footer.sellSafely') }}
						</LocalLink>
					</div>

					<div class="act_flex act_gap-1 act_pt-1 footer-soc">
						<a class="hover" :href="$tdb(settings[0], 'facebook')" target="_blank">
							<img
								loading="lazy"
								width="30"
								height="46"
								src="../assets/facebook-white.svg"
								alt="facebook"
							></a>
						<a class="hover" :href="$tdb(settings[0], 'instagram')" target="_blank">
							<img
								loading="lazy"
								width="30"
								height="46"
								src="../assets/instagram-white.svg"
								alt="instagram"
							></a>
						<a class="hover" :href="$tdb(settings[0], 'whatsapp')" target="_blank">
							<img
								loading="lazy"
								width="30"
								height="46"
								src="../assets/whatsapp-white.svg"
								alt="whatsapp"
							></a>
						<a class="hover" :href="$tdb(settings[0], 'youtube')" target="_blank">
							<img
								loading="lazy"
								width="30"
								height="46"
								src="../assets/youtube-white.svg"
								alt="youtube"
							></a>
					</div>
				</div>
				<div class="" :class="{ openFooter: openFooter === 'Transparency and Trust' }">
					<div class="footer-title" @click="toggleFooter('Transparency and Trust')">
						<p>
							{{ $t('footer.transparencyAndTrust') }}
						</p>
						<img loading="lazy" src="../assets/down-arrow.svg" alt="down">
					</div>
					<div class="footer-body">
						<LocalLink title="About Us" class="decoration-none block light hover" to="/about-us">
							{{ $t('footer.aboutUs') }}
						</LocalLink>
						<LocalLink title="FAQ" class="decoration-none block light hover" to="/about-us#faq">
							{{ $t('footer.faQs') }}
						</LocalLink>
						<LocalLink
							title="How to take a picture"
							class="decoration-none block light hover"
							to="/how-to-take-picture"
						>
							{{ $t('footer.howToTakePhotos') }}
						</LocalLink>
						<LocalLink
							title="How to pack your package"
							class="decoration-none block light hover"
							to="/how-to-pack-your-package"
						>
							{{ $t('footer.howToPack') }}
						</LocalLink>
						<LocalLink
							class="decoration-none block light hover"
							to="/why-sell-with-us"
							:title="$t('general.whySellWithUs')"
						>
							{{ $t('general.whySellWithUs') }}
						</LocalLink>
						<LocalLink
							v-for="page in legalLinks"
							:key="$tdb(page, 'slug')"
							:title="$tdb(page, 'name')"
							class="decoration-none block light hover"
							:to="$tdb(page, 'path')"
						>
							{{ $tdb(page, 'name') }}
						</LocalLink>
					</div>
				</div>
				<div class="" :class="{ openFooter: openFooter === 'Sell Online' }">
					<div class="footer-title" @click="toggleFooter('Sell Online')">
						<p>
							{{ $t('footer.sellOnline') }}
						</p>
						<img loading="lazy" src="../assets/down-arrow.svg" alt="down">
					</div>
					<div class="footer-body">
						<LocalLink
							:title="$t('footer.evaluateYourDiamond')"
							class="decoration-none block light hover"
							to="/valuate-diamond"
						>
							{{ $t('footer.evaluateYourDiamond') }}
						</LocalLink>
						<LocalLink
							v-for="sell in sellOnlineLinks"
							:key="sell.slugEN"
							:title="$tdb(sell, 'name')"
							class="decoration-none block light hover"
							:to="`${$tdb(sell, 'slug')}`"
						>
							{{ $tdb(sell, 'name') }}
						</LocalLink>
					</div>
				</div>
			</div>
		</div>
	</footer>
</template>

<style>
.footer-wrap {
	background: var(--blue-dark);
	position: relative;
	z-index: 1;
	margin-top: 5rem;
}

.footer {
	display: flex;
	gap: 4rem;
	padding: 5rem 0;
}

.footer-logo {
	width: 230px;
	margin-bottom: 2rem;
}

.footer-locations {
	display: grid;
	grid-template-columns: repeat(auto-fit, minmax(min(200px, 100%), 1fr));
	gap: 1rem;
	font-size: 14px;
}

.footer-locations b {
	display: block;
}

.footer-links {
	flex: 1 0 53%;
	font-size: 16px;
	display: grid;
	grid-template-columns: 1fr 1fr 1fr;
	gap: 2rem;
}

.footer-body * {
	padding-bottom: 1rem;
}

.footer-line {
	background: var(--blue);
	width: 1px;
}

.footer-title {
	margin-bottom: 1rem;
}

.footer-title img {
	display: none;
}
.calculator::before {
	content: '-';
	width: 12px;
	margin-inline-end: 2px;
	transform: translate(0, 6px);
	display: inline-block;
	background: url(../assets/calculator-small.svg);
	background-repeat: no-repeat;
	background-size: contain;
	color: transparent;
}

@media screen and (max-width: 900px) {
	.footer-locations {
		grid-template-columns: repeat(auto-fit, minmax(min(270px, 100%), 1fr));
		font-size: 12px;
	}

	.footer {
		flex-wrap: wrap;
		padding: 3rem 0 1rem;
		gap: 2rem;
		/* text-align: center; */
	}

	.footer-logo {
		width: 190px;
		margin-right: auto;
		margin-left: auto;
	}

	.footer-links {
		display: flex;
		flex-direction: column-reverse;
		/* font-size: 14px; */
		gap: 1px;
	}

	.footer-line {
		width: 100%;
		height: 1px;
	}

	.footer-soc {
		justify-content: center;
		gap: 2rem;
	}

	.footer-links p {
		color: var(--blue) !important;
	}

	.footer-title {
		display: flex;
		justify-content: space-between;
		align-items: center;
		border-bottom: 1px solid rgba(255, 255, 255, 0.2);
		padding-bottom: 1rem;
		cursor: pointer;
	}

	.footer-title img {
		display: block;
		transition: all 400ms ease;
	}

	.footer-body {
		max-height: 0;
		overflow: hidden;
		transition: all 400ms ease;
		transition-delay: 100ms;
	}

	.openFooter .footer-body {
		max-height: 50vh;
	}

	.openFooter .footer-title img {
		transform: rotate(180deg);
	}
}
</style>
