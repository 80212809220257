<script lang="ts" setup>import { computed as _computed } from 'vue';

import { useRouter } from 'vue-router'
import { useLangTools, translatePath, langFromPath } from '@/lang'

const props = defineProps<{
	title?: string
	to: string
}>()
const router = useRouter()
const { localizer } = useLangTools(router)
const lang = _computed(() => langFromPath(router.currentRoute.value.path))
const modTo = _computed(() => translatePath(String(props.to), lang.value))
</script>

<template>
	<RouterLink :title="title" :to="localizer(modTo)">
		<slot />
	</RouterLink>
</template>
