<template>
	<div class="line act_mx-auto act_mt-3 act_mb-3" />
</template>

<style>
.line{
	background: var(--blue);
	height: 1px;
	width: 100px ;
}
</style>
