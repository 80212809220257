<script setup lang="ts">import { computed as _computed } from 'vue';

import { useRouter } from 'vue-router'
import { settings } from '@/data/settings'
import { useLangTools } from '@/lang'

const router = useRouter()
const { $tdb } = useLangTools(router)

const videoURL = _computed(() => $tdb(settings[0], 'videoLink'))

const thumbnailUrl = _computed(() => {
	const ytID = videoURL.value.split('v=')[1] || videoURL.value.split('be/')[1]?.replace(/&.*/, '') || videoURL.value.split('/').pop()
	return `https://img.youtube.com/vi/${ytID}/maxresdefault.jpg`
})
</script>

<template>
	<div>
		<p class="act_center act_txt40 playfair act_mb-1 act_mt-3">
			{{ $t('general.ourStory') }}
		</p>
		<div v-lightbox="videoURL" class="pack-video act_w900 act_mt-1">
			<div class="video-container">
				<img width="900" height="506" :src="thumbnailUrl" alt="Video Thumbnail" class="video-thumbnail">
				<div class="play-icon hover">
					&#9658;
				</div>
			</div>
		</div>
	</div>
</template>

<style scoped>
.video-thumbnail {
	width: 100%;
	height: auto;
	cursor: pointer;
	border-radius: 20px;
}

.pack-video {
	overflow: hidden;
	position: relative;
	border-radius: 20px;
}
.pack-video iframe{
	aspect-ratio: 16/9;

}

.video-container {
	position: relative;
	display: flex;
	justify-content: center;
	align-items: center;
}

.play-icon {
	position: absolute;
	font-size: 1.5rem;
	color: var(--blue);
	opacity: 0.8;
	background: white;
	width: 50px;
	height: 50px;
	border-radius: 100%;
	cursor: pointer;
	display: flex;
	justify-content: center;
	align-items: center;
	padding-inline-start: 0.25rem;
}
</style>
