<script lang="ts" setup>import { computed as _computed } from 'vue';

import { ref } from 'vue'
import Modal from '@/components/Modal.vue'
import { langFromPath } from '@/lang'
import { useRoute } from 'vue-router'

const props = defineProps<{ data: { popupTitle: string, popupBody: string, acceptedItems: Array<{ id: string, icon: string, title: string, language: string }> } }>()

const route = useRoute()

const lang = _computed(() => langFromPath(route.fullPath))
const currentItem = ref<any>(null)
function openModal(item: any) {
	currentItem.value = item
}
</script>

<template>
	<div class="act_w1030 act_pt-1 act_pb-3">
		<Modal v-model:visible="currentItem">
			<div class="act_flex act_gap-1 act_pb-1">
				<h3 class="playfair act_txt30 act_no-marg">
					{{ currentItem?.popupTitle }}
				</h3>
			</div>
			<div v-html="currentItem?.popupBody" />
		</Modal>
		<div
			class="popups-wrap"
			:class="{ 'four-items': props.data.acceptedItems?.filter(i => i.language === lang).length === 4 }"
		>
			<div
				v-for="item in props.data.acceptedItems?.filter(i => i.language === lang)"
				:key="item.id"
				class="big-light-white-btn hover act_flex act_gap-2"
				@click="openModal(item)"
			>
				<img :src="item.icon" :alt="item.title">
				<p class="act_no-marg">
					{{ item.title }}
				</p>
			</div>
		</div>
	</div>
</template>

<style scoped>
.big-light-white-btn {
  cursor: pointer;
  background-color: #f5f5f5;
  padding: 20px;
  border-radius: 10px;
  transition: background-color 0.3s;
}
.big-light-white-btn:hover {
  background-color: #e0e0e0;
}
.grid-wrap {
  display: flex;
  flex-wrap: wrap;
}
.popups-wrap{
	display: flex;
	gap: 1rem;
	justify-content: center;
	flex-wrap: wrap;
}
.popups-wrap>div{
	min-width: 300px;
	flex: 0 0 300px;
}
.four-items {
	max-width: 800px;
	margin-inline: auto;
}
</style>
