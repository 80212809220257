import type { Plugin } from 'vue'
import { type BagelOptions, BagelVue, ModalPlugin } from '@bagelink/vue'
import { createGtm } from '@gtm-support/vue-gtm'
import { ViteSSG } from 'vite-ssg'
import LocalLink from './components/LocalLink.vue'
import App from './App.vue'
import { beforeEach, routes } from './router'
import clickOutside from './utils/clickOutside'
import { SpeecSynth } from './utils/speechSynthesis'
import { createPinia } from 'pinia'

const pinia = createPinia()

const bagelConfig: BagelOptions = {
	host: import.meta.env.VITE_BAGEL_BASE_URL,
	onError: (err: any) => {
		// const validationErrors = err?.response?.data?.detail && parseErrors(err?.response?.data?.detail)
		// const otherErrors = err.message || err
		// useToast().error(`${'An Error Occurred'}: ${validationErrors || otherErrors}`)
	},
}

import '@bagelink/vue/dist/style.css'
import '@/fonts.css'
import '@/main.css'
import { initSentry } from './initSentry'
import i18nInstance from '@/lang'

export const createApp = ViteSSG(
	App,
	{
		routes: routes(),
		scrollBehavior(to, from, savedPosition) {
			if (to.params.lang !== from.params.lang) return
			if (to.hash) {
				return {
					behavior: 'smooth',
					el: to.hash,
					offset: { top: 0, left: 0 },
				}
			}
			if (savedPosition) return savedPosition
			return { top: 0, left: 0 }
		},
	},
	({ app, router, isClient }) => {
		if (isClient) initSentry(app, router, import.meta.env.VITE_SENTRY_DSN)
		router.beforeEach(beforeEach)
		router.onError((error, to) => {
			if (error.message.includes('Failed to fetch dynamically imported module')) {
				Object.assign(window, { location: to.fullPath })
				error._skipSentry = true
			}
		})
		const gtm = createGtm({
			vueRouter: router as any,
			id: 'GTM-5SKV4D7',
			enabled: false,
		})
		app.use(pinia)
		app.use(BagelVue, bagelConfig)
		app.use(i18nInstance as unknown as Plugin)
		app.use(ModalPlugin as unknown as Plugin)
		app.directive('click-outside', clickOutside)
		app.use(SpeecSynth, { isClient })
		app.component('LocalLink', LocalLink)
		app.use(gtm as unknown as Plugin)
	},
)
