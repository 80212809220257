import { type BglFormSchemaT, bagelFormUtils } from '@bagelink/vue'
import { $t } from '@/lang'
import { telField } from './sellForm'
import CustomFileUpload from '@/components/CustomFileUpload.vue'

const { txtField, frmRow } = bagelFormUtils

export function submitSchema(): BglFormSchemaT {
	return [
		frmRow(
			txtField('first_name', 'First Name', { required: true }),
			txtField('last_name', 'Last Name', { required: true }),
		),
		txtField('email', 'Email', { required: true, type: 'email' }),
		telField('phone', 'Phone', { required: true, class: 'mb-1' }),
		frmRow(
			{
				$el: CustomFileUpload,
				id: 'images',
				label: $t('form.addImages'),
				attrs: {
					onPreviewImage: () => (file: any) => {
						// modalImage = URL.createObjectURL(file.file)
					},
					uploadIcon: 'image',
					multiple: true,
				}
			},
			{
				$el: CustomFileUpload,
				id: 'certificate',
				label: $t('form.addACertificateIfYouHaveOne'),
				attrs: {
					onPreviewImage: () => (file: any) => {
						// modalImage = URL.createObjectURL(file.file)
					},
					uploadIcon: 'certificate',

					multiple: true,
				}
			}
		),

	]
}
