// eslint-disable no-null
import ax from "axios";
import type { AxiosResponse } from "axios";
import type {
  Token,
  LoginReq,
  PasswordRecovery,
  NewPassword,
  SanitizedUserList,
  SanitizedUser,
  UserCreate,
  UserUpdateMe,
  UpdatePassword,
  UserRegister,
  UserUpdate,
  BasicSanitizedUser,
  StorageFile,
  DealWithProducts,
  ProductSubmission,
  Product,
  ProductWithImages,
  PipelineCountry,
  DealWithCustomer,
  Deal,
  ProductEval,
  DealAutomationKey,
  OfferUpdate,
  CourrierRequestWithRelationships,
  CourrierRequest,
  Pipeline,
  PipelineStage,
  Customer,
  NewCustomer,
  CustomerListResponse,
  CustomerWidthRelationships,
  FormSubmissionBase,
  ItemSubmission,
  FormSubmission,
  ContacForm,
  VIPForm,
  CommentWithReplies,
  Comment,
  HSWebhookPayload,
  HSPipeline,
  HSPipelineStage,
  HSOwner,
  ProjectSettings,
} from "./types.d";

export const axios = ax.create({
  baseURL: import.meta.env.VITE_BAGEL_BASE_URL,
});
export const checkDb = async (): Promise<
  AxiosResponse<{ [key: string]: any }>
> => axios.get("/check-db");
export const auth = {
  login: async (loginReq: LoginReq): Promise<AxiosResponse<Token>> =>
    axios.post("/auth/login", loginReq),
  passwordRecovery: async (passwordRecovery: PasswordRecovery) =>
    axios.post("/auth/password-recovery", passwordRecovery),
  resetPassword: async (newPassword: NewPassword) =>
    axios.post("/auth/reset-password", newPassword),
};
export const users = {
  getByUserId: async (userId?: string): Promise<AxiosResponse<SanitizedUser>> =>
    axios.get(`/users/${userId}`),
  patch: async (
    userUpdate: UserUpdate,
    userId?: string,
  ): Promise<AxiosResponse<SanitizedUser>> =>
    axios.patch(`/users/${userId}`, userUpdate),
  delete: async (userId?: string) => axios.delete(`/users/${userId}`),
  get: async (
    limit: number = 100,
    skip?: number,
  ): Promise<AxiosResponse<SanitizedUserList>> =>
    axios.get("/users/", { params: { skip, limit } }),
  post: async (userCreate: UserCreate): Promise<AxiosResponse<SanitizedUser>> =>
    axios.post("/users/", userCreate),
  me: {
    get: async (): Promise<AxiosResponse<SanitizedUser>> =>
      axios.get("/users/me"),
    patch: async (
      userUpdateMe: UserUpdateMe,
    ): Promise<AxiosResponse<SanitizedUser>> =>
      axios.patch("/users/me", userUpdateMe),
    password: async (updatePassword: UpdatePassword) =>
      axios.patch("/users/me/password", updatePassword),
  },
  signup: async (
    userRegister: UserRegister,
  ): Promise<AxiosResponse<SanitizedUser>> =>
    axios.post("/users/signup", userRegister),
};
export const auctenticAuth = {
  users: async (): Promise<AxiosResponse<BasicSanitizedUser[]>> =>
    axios.get("/auctentic_auth/users"),
};
export const files = {
  getById: async (id?: string): Promise<AxiosResponse<StorageFile>> =>
    axios.get(`/files/${id}`),
  put: async (
    storageFile: StorageFile,
    id?: string,
  ): Promise<AxiosResponse<StorageFile>> =>
    axios.put(`/files/${id}`, storageFile),
  delete: async (id?: string): Promise<AxiosResponse<{ [key: string]: any }>> =>
    axios.delete(`/files/${id}`),
  post: async (): Promise<AxiosResponse<StorageFile>> =>
    axios.post("/files", {}),
  get: async (topic?: string): Promise<AxiosResponse<StorageFile[]>> =>
    axios.get("/files", { params: { topic } }),
  upload: async (): Promise<AxiosResponse<StorageFile>> =>
    axios.post("/files/upload", {}),
};
export const staticFiles = {
  get: async (id?: string): Promise<AxiosResponse<StorageFile>> =>
    axios.get(`/static_files/${id}`),
  put: async (
    storageFile: StorageFile,
    id?: string,
  ): Promise<AxiosResponse<StorageFile>> =>
    axios.put(`/static_files/${id}`, storageFile),
  delete: async (id?: string): Promise<AxiosResponse<{ [key: string]: any }>> =>
    axios.delete(`/static_files/${id}`),
  post: async (topic?: string): Promise<AxiosResponse<StorageFile>> =>
    axios.post("/static_files", {}, { params: { topic } }),
  upload: async (topic?: string): Promise<AxiosResponse<StorageFile>> =>
    axios.post("/static_files/upload", {}, { params: { topic } }),
};
export const sales = {
  newDeal: async (
    productSubmission: ProductSubmission,
    customerId?: string,
  ): Promise<AxiosResponse<DealWithProducts>> =>
    axios.post(`/sales/new_deal/${customerId}`, productSubmission),
  deals: async (
    customerId?: string,
  ): Promise<AxiosResponse<DealWithProducts[]>> =>
    axios.get(`/sales/deals/${customerId}`),
  image: async (
    imageId?: string,
  ): Promise<AxiosResponse<{ [key: string]: any }>> =>
    axios.delete(`/sales/image/${imageId}`),
  product: {
    get: async (): Promise<AxiosResponse<Product[]>> =>
      axios.get("/sales/product/"),
    put: async (productDict: {
      [key: string]: any;
    }): Promise<AxiosResponse<ProductWithImages>> =>
      axios.put("/sales/product/", productDict),
  },
  deal: {
    getByDealId: async (
      dealId?: string,
    ): Promise<AxiosResponse<DealWithCustomer>> =>
      axios.get(`/sales/deal/${dealId}`),
    delete: async (
      dealId?: string,
    ): Promise<AxiosResponse<{ [key: string]: any }>> =>
      axios.delete(`/sales/deal/${dealId}`),
    put: async (
      dealDict: { [key: string]: any },
      dealId?: string,
    ): Promise<AxiosResponse<Deal>> =>
      axios.put(`/sales/deal/${dealId}`, dealDict),
    get: async (
      page: number = 1,
      country?: PipelineCountry,
      stageId?: string,
    ): Promise<AxiosResponse<DealWithCustomer[]>> =>
      axios.get("/sales/deal", {
        params: { country, stage_id: stageId, page },
      }),
    eval: async (
      evalDict: { [key: string]: any },
      dealId?: string,
    ): Promise<AxiosResponse<Deal>> =>
      axios.post(`/sales/deal/${dealId}/eval`, evalDict),
    stage: async (
      dealId?: string,
      stageKey?: DealAutomationKey,
    ): Promise<AxiosResponse<Deal>> =>
      axios.put(
        `/sales/deal/${dealId}/stage`,
        {},
        { params: { stage_key: stageKey } },
      ),
  },
  eval: {
    put: async (
      evalDict: { [key: string]: any },
      evalId?: string,
    ): Promise<AxiosResponse<ProductEval>> =>
      axios.put(`/sales/eval/${evalId}`, evalDict),
    delete: async (
      evalId?: string,
    ): Promise<AxiosResponse<{ [key: string]: any }>> =>
      axios.delete(`/sales/eval/${evalId}`),
  },
  offer: async (
    offerUpdate: OfferUpdate,
    offerId?: string,
  ): Promise<AxiosResponse<ProductEval>> =>
    axios.put(`/sales/offer/${offerId}`, offerUpdate),
  courrierRequest: {
    getByCourrierRequestId: async (
      courrierRequestId?: string,
    ): Promise<AxiosResponse<CourrierRequest>> =>
      axios.get(`/sales/courrier_request/${courrierRequestId}`),
    put: async (
      updateDict: { [key: string]: any },
      courrierRequestId?: string,
    ): Promise<AxiosResponse<CourrierRequest>> =>
      axios.put(`/sales/courrier_request/${courrierRequestId}`, updateDict),
    get: async (): Promise<AxiosResponse<CourrierRequestWithRelationships[]>> =>
      axios.get("/sales/courrier_request"),
    post: async (courrierRequestDict: {
      [key: string]: any;
    }): Promise<AxiosResponse<CourrierRequest>> =>
      axios.post("/sales/courrier_request", courrierRequestDict),
  },
  automationKeys: async (): Promise<AxiosResponse<string[]>> =>
    axios.get("/sales/automation_keys"),
  pipeline: {
    getByPipelineId: async (
      pipelineId?: string,
    ): Promise<AxiosResponse<Pipeline>> =>
      axios.get(`/sales/pipeline/${pipelineId}`),
    put: async (
      updateDict: { [key: string]: any },
      pipelineId?: string,
    ): Promise<AxiosResponse<Pipeline>> =>
      axios.put(`/sales/pipeline/${pipelineId}`, updateDict),
    get: async (): Promise<AxiosResponse<Pipeline[]>> =>
      axios.get("/sales/pipeline"),
  },
  pipelineCountry: async (): Promise<AxiosResponse<PipelineCountry[]>> =>
    axios.get("/sales/pipeline_country"),
  stage: {
    getByStageId: async (
      stageId?: string,
    ): Promise<AxiosResponse<PipelineStage>> =>
      axios.get(`/sales/stage/${stageId}`),
    put: async (
      updateDict: { [key: string]: any },
      stageId?: string,
    ): Promise<AxiosResponse<PipelineStage>> =>
      axios.put(`/sales/stage/${stageId}`, updateDict),
    get: async (): Promise<AxiosResponse<PipelineStage[]>> =>
      axios.get("/sales/stage"),
    post: async (stageDict: {
      [key: string]: any;
    }): Promise<AxiosResponse<PipelineStage>> =>
      axios.post("/sales/stage", stageDict),
  },
};
export const customer = {
  getByCustomerId: async (
    customerId?: string,
  ): Promise<AxiosResponse<CustomerWidthRelationships>> =>
    axios.get(`/customer/${customerId}`),
  put: async (
    newCustomer: NewCustomer,
    customerId?: string,
  ): Promise<AxiosResponse<CustomerWidthRelationships>> =>
    axios.put(`/customer/${customerId}`, newCustomer),
  post: async (newCustomer: NewCustomer): Promise<AxiosResponse<Customer>> =>
    axios.post("/customer/", newCustomer),
  get: async (
    page: number = 1,
    search?: string,
  ): Promise<AxiosResponse<CustomerListResponse[]>> =>
    axios.get("/customer/", { params: { page, search } }),
  otp: async (
    otpId?: string,
    emailOrPhone?: string,
  ): Promise<AxiosResponse<CustomerWidthRelationships>> =>
    axios.get(`/customer/otp/${otpId}`, {
      params: { email_or_phone: emailOrPhone },
    }),
};
export const form = {
  postById: async (
    formDict: { [key: string]: any },
    id?: string,
  ): Promise<AxiosResponse<{ [key: string]: any }>> =>
    axios.post(`/form/${id}`, formDict),
  getByFormName: async (
    formName?: string,
  ): Promise<AxiosResponse<{ [key: string]: any }[]>> =>
    axios.get(`/form/${formName}`),
  get: async (): Promise<AxiosResponse<FormSubmissionBase[]>> =>
    axios.get("/form"),
  post: async (formSubmission: {
    [key: string]: any;
  }): Promise<AxiosResponse<FormSubmissionBase>> =>
    axios.post("/form", formSubmission),
  itemSubmission: {
    post: async (
      itemSubmission: ItemSubmission,
    ): Promise<AxiosResponse<FormSubmission>> =>
      axios.post("/form/item_submission", itemSubmission),
    put: async (
      itemSubmission: ItemSubmission,
      id?: string,
      completeSubmission?: boolean,
    ): Promise<AxiosResponse<{ [key: string]: any }>> =>
      axios.put(`/form/item_submission/${id}`, itemSubmission, {
        params: { complete_submission: completeSubmission },
      }),
  },
  contactForm: async (
    contacForm: ContacForm,
  ): Promise<AxiosResponse<{ [key: string]: any }>> =>
    axios.post("/form/contact_form", contacForm),
  vipForm: async (
    vIPForm: VIPForm,
  ): Promise<AxiosResponse<{ [key: string]: any }>> =>
    axios.post("/form/vip_form", vIPForm),
};
export const comment = {
  put: async (
    comment: Comment,
    commentId?: string,
  ): Promise<AxiosResponse<CommentWithReplies[]>> =>
    axios.put(`/comment/${commentId}`, comment),
  post: async (
    comment: Comment,
    refId?: string,
  ): Promise<AxiosResponse<CommentWithReplies[]>> =>
    axios.post(`/comment/${refId}`, comment),
  get: async (refId?: string): Promise<AxiosResponse<CommentWithReplies[]>> =>
    axios.get(`/comment/${refId}`),
};
export const hubspot = {
  webhook: async (
    payload: HSWebhookPayload[],
  ): Promise<AxiosResponse<{ [key: string]: any }>> =>
    axios.post("/hubspot/webhook", payload),
  pipeline: {
    getByPipelineId: async (
      pipelineId?: string,
    ): Promise<AxiosResponse<HSPipeline>> =>
      axios.get(`/hubspot/pipeline/${pipelineId}`),
    put: async (
      updateDict: { [key: string]: any },
      pipelineId?: string,
    ): Promise<AxiosResponse<HSPipeline>> =>
      axios.put(`/hubspot/pipeline/${pipelineId}`, updateDict),
    get: async (): Promise<AxiosResponse<HSPipeline[]>> =>
      axios.get("/hubspot/pipeline"),
    post: async (hSPipeline: HSPipeline): Promise<AxiosResponse<HSPipeline>> =>
      axios.post("/hubspot/pipeline", hSPipeline),
  },
  customers: async () => axios.get("/hubspot/customers"),
  updatePipelines: async (): Promise<AxiosResponse<HSPipeline[]>> =>
    axios.get("/hubspot/update_pipelines"),
  stage: {
    get: async (): Promise<AxiosResponse<HSPipelineStage[]>> =>
      axios.get("/hubspot/stage"),
    post: async (
      hSPipelineStage: HSPipelineStage,
    ): Promise<AxiosResponse<HSPipelineStage>> =>
      axios.post("/hubspot/stage", hSPipelineStage),
    put: async (
      updateDict: { [key: string]: any },
      stageId?: string,
    ): Promise<AxiosResponse<HSPipelineStage>> =>
      axios.put(`/hubspot/stage/${stageId}`, updateDict),
  },
  owners: async (): Promise<AxiosResponse<HSOwner[]>> =>
    axios.get("/hubspot/owners"),
  deals: async () => axios.get("/hubspot/deals"),
};
export const documentTemplate = {
  getByTemplateName: async (
    templateName?: string,
  ): Promise<AxiosResponse<string>> =>
    axios.get(`/document_template/${templateName}`),
  put: async (
    templateName?: string,
    content?: string,
  ): Promise<AxiosResponse<string>> =>
    axios.put(
      `/document_template/${templateName}`,
      {},
      { params: { content } },
    ),
  post: async (
    templateName?: string,
    content?: string,
  ): Promise<AxiosResponse<string>> =>
    axios.post(
      `/document_template/${templateName}`,
      {},
      { params: { content } },
    ),
  get: async (): Promise<AxiosResponse<string[]>> =>
    axios.get("/document_template"),
};
export const contract = async (templateName?: string) =>
  axios.get(`/contract/${templateName}`);
export const generatePdf = async (
  data: { [key: string]: any },
  templateName?: string,
): Promise<AxiosResponse<StorageFile>> =>
  axios.post(`/generate_pdf/${templateName}`, data);
export const projectSettings = {
  delete: async (
    key?: string,
  ): Promise<AxiosResponse<{ [key: string]: any }>> =>
    axios.delete(`/project_settings/${key}`),
  getById: async (id?: string): Promise<AxiosResponse<ProjectSettings>> =>
    axios.get(`/project_settings/${id}`),
  put: async (projectSettings: ProjectSettings, id?: string) =>
    axios.put(`/project_settings/${id}`, projectSettings),
  get: async (): Promise<AxiosResponse<ProjectSettings[]>> =>
    axios.get("/project_settings"),
  post: async (projectSettings: ProjectSettings) =>
    axios.post("/project_settings", projectSettings),
};
