<script setup lang="ts">import { ref as _ref, computed as _computed } from 'vue';

import { useRouter } from 'vue-router'
import { onMounted } from 'vue'
import BotFooter from '@/components/BotFooter.vue'
import TopNav from '@/components/TopNav.vue'
import EvaluateLink from '@/components/EvaluateLink.vue'
import FaqHome from '@/components/FaqHome.vue'
import Vip from '@/components/Vip.vue'
// import ReviewsGoogle from '@/components/ReviewsGoogle.vue';
// import RecentlySold from '@/components/RecentlySold.vue';
import CatalogSlider from '@/components/CatalogSlider.vue'
import Reviews from '@/components/Reviews.vue'
import Testimonials from '@/components/Testimonials.vue'
import Timeline from '@/components/Timeline.vue'
import GetMore from '@/components/GetMore.vue'
import VideoHome from '@/components/VideoHome.vue'

import ContinueSubmission from '@/components/ContinueSubmission.vue'
import { googleReviews } from '@/data/googleReviews'
import { tp } from '@/data/trustpilot'
// import ThreeD from '@/components/ThreeD.vue';
import { settings } from '@/data/settings'
import { useLangTools } from '@/lang'

const router = useRouter()
const { $tdb } = useLangTools(router)

let activeTitleSlide = _ref(0)

const [pageSettings] = settings
function changeTitleSlide() {
	activeTitleSlide.value =
		activeTitleSlide.value === pageSettings.homePageTitle.length - 1 ? 0 : activeTitleSlide.value + 1
	setTimeout(changeTitleSlide, 5000)
}

onMounted(() => setTimeout(changeTitleSlide, 5000))

const trustpilotData = _computed(() => {
	const LocalBusiness = tp.find((item: any) => item['@type'] === 'LocalBusiness')
	return LocalBusiness?.aggregateRating
})
</script>

<template>
	<div class="home-page">
		<div class="act_oval" />
		<TopNav />
		<div class="page-wrap">
			<ContinueSubmission />

			<div class="act_center act_w1170 hero-wrap act_start">
				<div class="hero">
					<div class="hero-img">
						<!-- <ClientOnly>
							<ThreeD/>
						</ClientOnly> -->
						<img class="hero-img-desktop" width="580" height="690" src="../assets/main-image.webp" alt="Auctentic">
						<img class="hero-img-mobile" width="180" height="215" src="../assets/main-image-mobile.webp" alt="Auctentic">

						<!-- <video autoplay muted loop>
							<source src="/model/Pear_Diamond.mp4" type="video/mp4" />
						</video> -->
					</div>
					<div class="hero-txt">
						<h1 class="act_pb-1 h1-title">
							{{ $t('home.title') }}
						</h1>
						<div class="hero-title-wrap">
							<div
								v-for="(title, i) in pageSettings.homePageTitle"
								:key="title._id"
								class="title-slide"
								:class="{ 'active-title': i === activeTitleSlide }"
							>
								<p class="playfair act_txt60 act_no-marg act_pb-2 hero-title">
									{{ $tdb(title, 'title').split('\n')[0] }}
									<!-- {{ $t('home.slogan') }} -->
									<span class="act_blue">{{ $tdb(title, 'title').split('\n')[1] }}</span>
								</p>
							</div>
						</div>
						<LocalLink class="act_btn get-start" to="/get-the-best-offer">
							{{ $t('cta.getStarted') }}
						</LocalLink>
					</div>
				</div>
				<div class="act_flex justify-content-space-between hero-logos act_pb-4">
					<div class="m_act_w100">
						<a
							href="https://www.trustpilot.com/review/auctentic.com"
							target="_blank"
							class="act_flex act_gap-05 top-review act_mb-1"
							:title="$t('home.readOurReviews')"
						>
							<p>Excellent {{ trustpilotData?.ratingValue }}</p>
							<img width="95" height="21" src="../assets/trust-stars.svg" alt="Trust Stars">
							<p>{{ trustpilotData?.reviewCount }} reviews on</p>
							<img class="trust-review-logo" width="91" height="25" src="../assets/trust-logo-white.svg" alt="Trust Stars">
						</a>
						<a
							href="https://maps.app.goo.gl/Bp2TbnDvp5zyM9Wx7"
							target="_blank"
							class="act_flex act_gap-05 top-review"
						>
							<p id="google-rating">{{ googleReviews.rating }}</p>
							<div class="google-stars">
								<img width="21" height="18" src="../assets/star.svg" alt="Star">
								<img width="21" height="18" src="../assets/star.svg" alt="Star">
								<img width="21" height="18" src="../assets/star.svg" alt="Star">
								<img width="21" height="18" src="../assets/star.svg" alt="Star">
								<img width="21" height="18" src="../assets/star.svg" alt="Star">
							</div>
							<p><span id="google-review-count">{{ googleReviews.reviews }}</span> reviews on</p>
							<img class="google-review-logo" height="21" src="../assets/Google-white.svg" alt="Google">
						</a>
					</div>
					<div class="act_flex act_gap-1 parter-logos m_act_w100">
						<img
							class="parter-logo"
							width="115"
							height="43"
							src="../assets/Gemological-Institute-America-white.svg"
							alt="Gemological-Institute-America"
						>
						<img
							class="parter-logo"
							width="115"
							height="43"
							src="../assets/igi-white.svg"
							alt="IGI"
						>
						<img
							class="parter-logo"
							width="115"
							height="43"
							src="../assets/hrd-white.png"
							alt="HRD"
						>
					</div>
				</div>
			</div>
			<GetMore />
			<Timeline />
			<VideoHome />
			<Testimonials />
			<Reviews />
			<!-- <ReviewsGoogle /> -->
			<!-- <RecentlySold /> -->
			<CatalogSlider />
			<Vip />
			<FaqHome />
			<EvaluateLink />
		</div>
		<BotFooter />
	</div>
</template>

<style>
.get-start {
	position: relative;
	z-index: 9;
}
.hero {
	display: flex;
	flex-direction: row-reverse;
	align-items: center;
	height: 90%;
}

.hero-txt {
	flex: 1 670px;
}
.h1-title {
	font-weight: 400;
	font-size: 18px;
	margin: 0;
}

.hero-title {
	font-size: 54px !important;
	max-width: 860px;
	width: calc(100% - 280px);
}
.title-slide {
	position: absolute;
	width: 100%;
	opacity: 0;
	flex-shrink: 0;
	transition: all 1000ms ease-in-out;
}

.title-slide.active-title {
	opacity: 1;
	position: relative;
}

.hero-title-wrap {
	min-height: 240px;
	display: flex;
	height: auto;
}

.hero-txt span {
	display: block;
}

.hero-img img {
	position: absolute;
	top: 7vh;
	max-width: 580px;
	width: 50%;
	inset-inline-end: -10.1%;
}
.hero-img video {
	mix-blend-mode: lighten;
	position: absolute;
	top: 15%;
	inset-inline-start: 70%;
}

.hero-logos {
	align-items: center;
}

.hero-wrap {
	padding: 10vh 0 5vh;
	height: 90vh;
	margin-top: -90px !important;
	position: relative;
}
.home-page {
	overflow: hidden;
}
.google-stars{
	display: inline-block;
	margin-bottom: 2px;
}
.google-stars img{
	height: 18px;
	width: 21px;
}
.top-review {
	align-items: flex-end;
	text-decoration: none;
}
.top-review img{
	margin-bottom: 2px;
}
.hero-img-mobile{
		display: none;
	}

@media screen and (max-width: 1100px) {
	.hero {
		padding-bottom: 1rem;
	}

	.hero-txt {
		flex: 1 0 670px;
	}
	.hero-title {
		/* margin-inline-end: 15rem; */
		/* white-space: unset; */
		/* padding-inline-end: 200px */
	}

	.hero-img img {
		top: 130px;
		width: 50%;
		/* inset-inline-end: -1%; */
	}
}

@media screen and (max-width: 940px) {
	.hero-img-desktop{
		display: none
	}
	.hero-img-mobile{
		display: unset
	}
	.hero-img img {
		top: 0vh;
		/* max-height: 90vh; */
		/* object-fit: contain; */
	}
	.hero-img video {
		position: relative;
		top: 0%;
		inset-inline-start: 0%;
		height: 200px;
	}
	.hero-title {
		white-space: unset;
		margin-inline-end: 0;
		padding-inline-end: 0;
		max-width: 100%;
		width: auto;
		font-size: 30px !important;
		letter-spacing: -0.4px;
	}

	.hero {
		display: block;
		text-align: center;
	}

	.hero-logos {
		flex-wrap: wrap;
		gap: 2rem;
		justify-content: center !important;
		/* flex-direction: column-reverse; */
		/* margin-top: 2rem; */
		object-fit: contain;
		width: 90%;
		margin-inline: auto;
	}

	.top-review {
		display: flex !important;
		flex-wrap: wrap;
		justify-content: center;
		gap: 0.1rem;
		font-size: 12px;
	}
	.top-review img{
		width: 64px;
		margin: 0%;
	}
	.google-stars{
	display: inline-block;
	margin-bottom: 2px;
}
.trust-review-logo{
	height: 18px;
	width: auto !important;
	margin-bottom: 2px !important;
}

.google-review-logo{
	height: 15px;
	width: auto !important;
}

.google-stars img{
	height: 13px;
	width: 13px;
}

	.parter-logos {
		justify-content: space-between;
		width: 100%;
		flex-wrap: wrap;
		max-width: 400px;
		margin-top: auto;
		/* transform: translateY(-1rem); */
	}

	.parter-logo {
		width: 84px;
		opacity: 0.8;
	}

	.hero-img {
		height: 215px;
		margin-bottom: -1.5rem;
	}

	.hero-img img {
		height: 100%;
		width: 180px;
		max-width: none;
		inset-inline-end: 0;
		position: relative;
	}

	.mobile-hero {
		display: block;
		height: 73vw;
		max-height: 600px;
		margin-inline-start: 0rem;
		margin-bottom: -5rem;
		margin-top: 2vw;
	}

	.hero-wrap {
		padding: 80px 0 0;
		height: unset;
		min-height: unset;
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		height: 100svh;
		min-height: 660px;
	}
	p.hero-title {
		width: 90vw;
		white-space: unset;
	}

	.hero-title-wrap{
	min-height: 150px;
	height: auto;
	align-items: center;
}
.h1-title{
		display: none
	}
}

@media screen and (max-height: 800px) {
	.hero-img img {
		top: 3vh;
		max-height: 90vh;
		object-fit: contain;
	}
	/* .m_act_w100{
		width: 100%;
		margin-inline: auto ;
	} */
}

@media screen and (max-width: 500px) {
	.hero-wrap {
		min-height: calc(100vh - 70px);
		min-height: 680px;
		width: 95% !important;
		height: auto;

	}
	p.hero-title {
		width: unset;
	}
}
@media screen and (max-width: 350px) {
		.hero-img {
			height: 180px;
		}
	.hero-wrap {
		height: auto;
	}
}
@media screen and (min-height: 1000px) {

.hero-img img {
	position: relative;
	top: 0;
	width: 580px;
	inset-inline-end: -120px;
	margin-inline-start: -300px;
}
}
</style>
