<script setup lang="ts">import { ref as _ref } from 'vue';

let moreTabActive = _ref('Value')
let moreTabBodyActive = _ref('Value')

function setActiveMoreTab(key: string) {
	moreTabActive.value = key
	moreTabBodyActive.value = key
}
</script>

<template>
	<div class="dark-bg act_pt-4 act_pb-4">
		<p class="playfair act_txt40 act_center act_no-marg act_pb-2">
			{{ $t('home.getMore') }}
		</p>
		<div class="act_w1170 act_flex act_center top-icon-wrap">
			<LocalLink to="/how-does-it-work#ExpertEvaluation" class="top-icon">
				<img width="64" height="80" src="../assets/sell-safely-icon-8.svg" :alt="$t('home.eval')">
				<p>{{ $t('home.eval') }}</p>
			</LocalLink>
			<LocalLink to="/how-does-it-work#Getthebestoffer" class="top-icon">
				<img width="64" height="80" src="../assets/sell-safely-icon-10.svg" :alt="$t('home.findBuyers')">
				<p class="balance">
					{{ $t('home.findBuyers') }}
				</p>
			</LocalLink>
			<LocalLink to="/how-does-it-work#Getthebestoffer" class="top-icon">
				<img width="64" height="80" src="../assets/hand-shake.svg" :alt="$t('home.negotiate')">
				<p>{{ $t('home.negotiate') }}</p>
			</LocalLink>
			<LocalLink to="/how-does-it-work#QuickandSafePayment" class="top-icon">
				<img width="64" height="80" src="../assets/sell-safely-icon-12.svg" :alt="$t('home.guaranteedPayment')">
				<p>{{ $t('home.guaranteedPayment') }}</p>
			</LocalLink>
		</div>
		<!-- <div class="act_w700">
			<p class="playfair act_txt60 act_center act_no-marg act_pb-2 act_blue">
				{{ $t('home.getMore') }}
			</p>
			<div class="more-tabs-wrap">
				<div class="more-tabs act_mb-2">
					<div
						class="more-tab hover act_flex act_gap-1 align-center"
						:class="{ moreTabActive: 'Value' === moreTabActive }" @click="setActiveMoreTab('Value')"
					>
						<img width="50" height="45" src="../assets/wallet.svg" alt="wallet">
						<h2 class="playfair act_no-marg act_txt30 act_blue">
							{{ $t('home.value') }}
						</h2>
					</div>
					<div
						class="more-tab hover act_flex act_gap-1 align-center"
						:class="{ moreTabActive: 'Convenience' === moreTabActive }"
						@click="setActiveMoreTab('Convenience')"
					>
						<img width="50" height="45" src="../assets/coffee.svg" alt="coffee">
						<h2 class="playfair act_no-marg act_txt30 act_blue">
							{{ $t('home.convenience') }}
						</h2>
					</div>
					<div
						class="more-tab hover act_flex act_gap-1 align-center"
						:class="{ moreTabActive: 'Security' === moreTabActive }" @click="setActiveMoreTab('Security')"
					>
						<img width="50" height="45" src="../assets/lock.svg" alt="lock">
						<h2 class="playfair act_no-marg act_txt30 act_blue">
							{{ $t('home.security') }}
						</h2>
					</div>
				</div>
				<div class="more-bodies">
					<div class="more-body" :class="{ moreTabBodyActive: 'Value' === moreTabBodyActive }">
						<h3 class="playfair act_txt30 act_blue act_no-marg">
							{{ $t('home.expertAssessment') }}
						</h3>
						<p class="act_pb-2">
							{{ $t('home.specializedGemologist') }}
						</p>
						<h3 class="playfair act_txt30 act_blue act_no-marg">
							{{ $t('home.globalReach') }}
						</h3>
						<p class="act_pb-2">
							{{ $t('home.networkEncompasses') }}
						</p>
						<h3 class="playfair act_txt30 act_blue act_no-marg">
							{{ $t('home.noSellerFees') }}
						</h3>
						<p class="act_pb-2">
							{{ $t('home.weDonTChargeYouADime') }}
						</p>
					</div>
					<div class="more-body" :class="{ moreTabBodyActive: 'Convenience' === moreTabBodyActive }">
						<h3 class="playfair act_txt30 act_blue act_no-marg">
							{{ $t('home.expertNegotiation') }}
						</h3>
						<p class="act_pb-2">
							{{ $t('home.leaveTheIntricateDealingsToUs') }}
						</p>
						<h3 class="playfair act_txt30 act_blue act_no-marg">
							{{ $t('home.secureShipment') }}
						</h3>
						<p class="act_pb-2">
							{{ $t('home.timelyDeliveries') }}
						</p>
						<h3 class="playfair act_txt30 act_blue act_no-marg">
							{{ $t('home.idealBuyers') }}
						</h3>
						<p class="act_pb-2">
							{{ $t('home.potentialBuyers') }}
						</p>
					</div>
					<div class="more-body" :class="{ moreTabBodyActive: 'Security' === moreTabBodyActive }">
						<h3 class="playfair act_txt30 act_blue act_no-marg">
							{{ $t('home.safeTransactions') }}
						</h3>
						<p class="act_pb-2">
							{{ $t('home.paymentMethods') }}
						</p>
					</div>
				</div>
			</div>
		</div> -->
	</div>
</template>

<style>
.more-tabs-wrap {
	/* display: grid;
	grid-template-columns: 1fr 1fr;
	gap: 2rem; */
}

.more-tabs {
	border-inline-end: 1px solid rgba(255, 255, 255, 0.2);
	border-inline-end: none;
	display: flex;
	overflow: auto;
	gap: 0rem;
	margin: 0 -5%;
	padding: 0 1rem;
}

.more-body {
	display: none;
	opacity: 0;
}

.moreTabBodyActive {
	display: block;
	opacity: 1;
	animation: moreTabBodyActive 0.4s ease-in-out;
}

@keyframes moreTabBodyActive {
	0% {
		opacity: 0;
	}

	100% {
		opacity: 1;
	}
}

.more-tab {
	padding: 1rem;
	border-radius: 20px;
	padding-inline-end: 3rem;
}

.moreTabActive {
	background: linear-gradient(90deg, rgba(88, 191, 235, 0.2) 1.58%, rgba(88, 191, 235, 0) 100%);
	background: linear-gradient(180deg, rgba(88, 191, 235, 0.2) 1.58%, rgba(88, 191, 235, 0) 100%);
}

.moreTabActive img {
	filter: brightness(0) invert();
}

.moreTabActive h2 {
	color: white;
}
.top-icon {
	width: 170px;
	cursor: pointer;
	transition: all 200ms ease;
	text-decoration: none;
}
.top-icon:hover{
	filter: brightness(90%);
}
.top-icon:active{
	filter: brightness(80%);
}
.top-icon img {
	height: 80px;
	padding-bottom: 1rem;
}
.top-icon-wrap {
	gap: 1rem;
	justify-content: center;
	flex-wrap: wrap;
}

@media screen and (max-width: 900px) {
	.more-tabs-wrap {
		grid-template-columns: 1fr;
	}

	.more-tabs {
		border-inline-end: none;
		display: flex;
		overflow: auto;
		gap: 0rem;
		margin: 0 -5%;
		padding: 0 1rem;
	}
	.more-tabs::-webkit-scrollbar {
		display: none !important;
		opacity: 0;
	}

	.moreTabActive {
		background: linear-gradient(180deg, rgba(88, 191, 235, 0.2) 1.58%, rgba(88, 191, 235, 0) 100%);
	}

	.more-body {
		padding: 0 1rem;
	}

	.more-tab {
		width: 100%;
		white-space: nowrap;
		flex-wrap: wrap;
		justify-content: center;
		padding: 0.75rem;
		flex-direction: column;
	}

	.more-tab * {
		font-size: 18px !important;
	}
	.top-icon {
		width: 150px;
	}
	.top-icon-wrap {
		gap: 1rem;
		justify-content: center;
		flex-wrap: wrap;
	}
}
</style>
