import type { DirectiveBinding } from 'vue'

export default (el: Element, binding: DirectiveBinding) => {
	if (typeof binding.value !== 'function') {
		const compName = el.tagName
		let warn = `[vue-click-outside:] provided expression ${binding.value} is not a function, but has to be`
		if (compName) warn += ` Found in component ${compName}`
		console.warn(warn)
	}
	const clickOutsideEvent = (event: MouseEvent) => {
		const clickedInside = el.contains(event.target as Node)
		if (!clickedInside) {
			binding.value?.()
		}
	}
	document.body.addEventListener('click', clickOutsideEvent)
}
