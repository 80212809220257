import type { InjectionKey, Plugin } from 'vue'
import { inject } from 'vue'

export const speechInjectionKey = Symbol('speech') as InjectionKey<any>

export function useSpeech() {
	const spch = inject(speechInjectionKey)
	if (!spch) throw new Error('Install Speech first')

	return spch
}

function speech(isClient: boolean) {
	if (!isClient) {
		return {
			isSupported: false,
			cancel: () => { },
			pause: () => { },
			resume: () => { },
			speak: () => { },
		}
	}

	const synth = window.speechSynthesis

	return {
		isSupported: true,
		cancel: () => { synth.cancel() },
		pause: () => { synth.pause() },
		resume: () => { synth.resume() },
		speak: (text: string, lang: string) => {
			const utterThis = new SpeechSynthesisUtterance(text)
			utterThis.lang = lang
			utterThis.pitch = 1
			utterThis.rate = 1
			utterThis.volume = 1
			synth.speak(utterThis)
		},
	}
}

export const SpeecSynth: Plugin = {
	install: (app, options: { isClient: boolean }) => {
		const speechInstance = speech(options.isClient)
		app.provide(speechInjectionKey, speechInstance)
	},
}
