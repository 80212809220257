export const baseUrl = 'https://auctentic.com';

export const paramToCol: Record<string, string> = {
	related_catgory: 'blogCategories',
	category: 'blogCategories',
	article_slug: 'blog',
	product_slug: 'products',
	legal_slug: 'legalPages',
	// sell_slug: 'sellSafely',
	event_slug: 'events',
	sell_slug: 'sellPageBlocks',
}
