<script lang="ts" setup>
const props = defineProps<{ data: Record<string, any> }>()
const fallbackImg = 'https://bagel.sfo2.cdn.digitaloceanspaces.com/Bagelink/placeholder-image.svg'
</script>

<template>
	<div class="act_pt-3 act_pb-4 w790 act_mx-auto">
		<img class="full-img act_round" :src="data.image || fallbackImg" :alt="data.alt" :class="data.align">
		<!-- <img :id="data.img_id" class="full-imgact_round" alt=""> -->
	</div>
</template>
