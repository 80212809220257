import { getDiamondValue, isVisible } from './utils'
import { i18n, localizer } from './lang'
import { api } from './utils/db'
import { defineStore } from 'pinia'
import { useGtm } from '@gtm-support/vue-gtm'

let gtm = useGtm()

function getFormStep() {
	const sliders = document.querySelectorAll('[form-step]')
	if (!sliders) return 0
	const allSlides = Array.from(sliders)
	const visibleElement = allSlides.filter(el => isVisible(el as HTMLElement)).shift()
	if (!visibleElement) return ''
	const formStep = visibleElement.getAttribute('form-step')
	return formStep
}

function getStepIndex() {
	const slider = document.querySelector('.act_bgl-slider')
	if (!slider) return 0
	const allSlides = Array.from(slider.children)
	const visibleElement = allSlides.filter(el => isVisible(el as HTMLElement)).shift()
	if (!visibleElement) return 0
	return allSlides.indexOf(visibleElement)
}

export interface FormT {
	[key: string]: any
	item?: string
	certificate_type?: string
	isUnqualified?: boolean
}
function getResetForm(): FormT {
	return {
		first_name: '',
		last_name: '',
		item: undefined,
		read_privacy_policy: false,
	}
}

export const useStore = defineStore('jewlery', {
	state: () => ({
		isUploading: false,
		stepIndex: 0,
		lastStep: false,
		stepID: '',
		name: '',
		form: getResetForm(),
		submitting: false,
		submitID: '',
	}),
	getters: {
		is49CaratRing(state): boolean {
			return state.form.item === 'Ring' && state.form.carat === '0-0.49 ct.'
		},

		isUnqualifiedDiamond(state): boolean {
			return (
				state.form.item === 'Diamond' &&
				['carat_range', 'color', 'clarity'].every(key => state.form[key]) &&
				state.form.carat_range === '0-0.49 ct.'
			)
		},

		is49CaratGemstone(state): boolean {
			return state.form.item === 'Gemstone' && state.form.carat_range === '0-1.49 ct.'
		},

		isLessThanHalfCaratJewelry(state): boolean {
			return ['Bracelet', 'Necklace', 'Earring', 'Brooch'].includes(state.form.item ?? '') && +state.form.carat < 0.5
		},

		isUnqualifiedCertType(state): boolean {
			return (
				['AIG', 'GemReport Antwerp (gra)', 'GwLab', 'ALGT'].includes(state.form.certificate_type ?? '')
			)
		}

	},
	actions: {
		async preSubmitForm() {
			const item = this.form.item?.toLowerCase().replace(/\s/g, '_')
			if (item && ['diamond', 'ring'].includes(item)) this.form.estimated_value = getDiamondValue(this.form).average
			if (this.submitID) {
				await api.updateSubmission(this.submitID, this.form)
			} else {
				this.submitID = await api.postSubmission(this.form)
			}
		},
		
		async submitForm() {
			if (this.submitting) return
			if (this.isUploading) {
				setTimeout(async () => this.submitForm(), 1000)
				return
			}
			this.submitting = true
			try {
				const item = this.form.item?.toLowerCase().replace(/\s/g, '_')
				if (this.submitID) await api.updateSubmission(this.submitID, this.form, true)
				else this.submitID = await api.postSubmission(this.form)
				const query: Record<string, any> = {}
				query.withImage = this.form.images?.length > 0
				const event: Record<string, any> = {
					event: 'form_submit',
					form_name: `${item}_funnel`,
					form_step: 'thank_you_step',
					value: this.form.estimated_value,
				}
				gtm?.trackEvent(event)
				const path = localizer('thank-you')
				window.location.href = `${path}?${new URLSearchParams({ ...query, e: this.form.email, p: this.form.phone, fn: this.form.first_name, ln: this.form.last_name }).toString()}`
			} catch (error) {
				console.error(error)
				this.submitting = false
			}
		},
		parseCaratRange() {
			if (this.form.carat_range) {
				const [min, max] = this.form.carat_range?.match(/\d?\.?\d+/g) || [null, null]
				this.form.carat_min = Number.isNaN(min) ? null : min
				this.form.carat_max = Number.isNaN(max) ? null : max
			}
		},

		resetForm() {
			Object.keys(this.form).forEach(key => delete this.form[key])
			const resetform = getResetForm()
			Object.assign(this.form, resetform)
		},

		async nextStep(stepID?: string) {
			window.scrollTo({ top: 0, behavior: 'smooth' })
			this.parseCaratRange()
			this.checkUnqualified()
			this.stepIndex++
			setTimeout(() => {
				const formStep = getFormStep()
				if (formStep) this.trackStep(formStep)
			}, 700)
		},

		trackStep(step: string) {
			const item = this.form.item?.toLowerCase().replace(/\s/g, '_')
			const event: Record<string, any> = {
				event: 'form_submit',
				form_name: `${item}_funnel`,
				form_step: `${step}_step`,
			}
			if (!gtm) gtm = useGtm()
			gtm?.trackEvent(event)
		},

		checkUnqualified() {
			if (
				this.is49CaratRing ||
				this.is49CaratGemstone ||
				this.isLessThanHalfCaratJewelry ||
				this.isUnqualifiedDiamond ||
				this.isUnqualifiedCertType
			) {
				this.form.isUnqualified = true
			}
		},
		prevStep() {
			this.stepIndex--
		},
		setName(name: string) {
			this.name = name
		},
	},
})
