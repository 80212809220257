<script lang="ts" setup>
const props = defineProps<{ data: { title: string, body: string, image: string } }>()
</script>

<template>
	<div class="how-item white-box act_gap-2 act_flex align-center mb-2 act_w970">
		<img class="act_round how-img relative" :src="props.data.image" :alt="props.data.title">
		<div>
			<h3 class="playfair act_txt30 act_no-marg act_pb-1">
				{{ props.data.title }}
			</h3>
			<div
				class="how-item-txt"
				v-html="props.data.body"
			/>
		</div>
	</div>
</template>

<style scoped>
.act_bg-white .how-item.white-box{
    outline: 1px solid #b7b7b7;
}
</style>
