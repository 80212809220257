<script setup lang="ts">
import { onMounted } from 'vue'
import { useRoute } from 'vue-router'
import { useGtm } from '@gtm-support/vue-gtm'
import { setSessionStorage } from './utils'

const gtm = useGtm()

function enableGtm() {
	gtm?.enable(true)
	document.removeEventListener('mousemove', enableGtm)
	document.removeEventListener('touchstart', enableGtm)
}
const route = useRoute()

const refreshInAnHour = () => setTimeout(() => { window.location.reload() }, 60 * 60 * 1000)

onMounted(() => {
	refreshInAnHour()
	if (route.path.match('thank-you')) { enableGtm()
	}
	else {
		document.addEventListener('mousemove', enableGtm)
		document.addEventListener('touchstart', enableGtm)
	}
})

function saveQueryParams() {
	const query = JSON.stringify(route.query)
	setSessionStorage('query', query)
	setSessionStorage('landTime', `${new Date().getTime()}`)
}
onMounted(() => setTimeout(saveQueryParams, 100))
</script>

<template>
	<ModalContainer/>
	<router-view />
</template>
