<script setup lang="ts">import { ref as _ref } from 'vue';

import { watch } from 'vue'
import { useStore } from '@/store'

type OptionValue = string | string[] | undefined

type Option =
	| {
		name: string
		value?: string
		image?: string
	}
	| string

const props = defineProps<{
	label?: string
	id?: string
	hidename?: boolean
	modelValue?: OptionValue
	slots?: any[]
	asArray?: boolean
	options: Option[]
}>()

const emit = defineEmits(['update:modelValue'])
const store = useStore()
const slotField = _ref<Record<string, any>>({})

const optionToName = (option: Option) => (typeof option === 'string' ? option : option.name)
const optionToImage = (option: Option) => (typeof option === 'string' ? '' : option.image)
function optionToValue(option: Option) {
	if (typeof option === 'string') return option
	if (option.value === undefined) return option.name
	return option.value
}

let val = _ref<OptionValue>(props.modelValue)

function isEqual(option: Option) {
	const value = optionToValue(option)
	if (val.value && Array.isArray(val.value)) return val.value.includes(value)
	return val.value === (value as OptionValue)
}

watch(
	() => slotField.value,
	() => {
		if (props.id && slotField.value[props.id]) val.value = `${slotField.value[props.id]}`
	},
	{ deep: true },
)

function selectOption(option: Option) {
	const value = optionToValue(option)
	if (props.asArray) val.value = [value]
	else val.value = value
	emit('update:modelValue', val.value)
}
</script>

<template>
	<div class="act_w900 act_pb-3">
		<h3 class="playfair act_txt24 act_no-marg act_pb-1 pretty">
			{{ label }}
		</h3>
		<div class="valuate-options-wrap act_flex wrap">
			<div
				v-for="c in options.filter((c) => optionToValue(c) !== 'Other')"
				:key="optionToValue(c)"
				class="valuate-option hover"
				:class="{ wizOptionActive: isEqual(c) }"
				@click="selectOption(c)"
			>
				<img v-if="optionToImage(c)" :src="optionToImage(c)" :alt="optionToName(c)">
				<p>{{ $t(optionToName(c)) }}</p>
			</div>
		</div>
	</div>
</template>
