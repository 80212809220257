// import { createI18nPlugin } from '@formkit/i18n';
import { blog } from '../data/blog'
import { blogCategories } from '../data/blogCategories'
import { en } from './en'
import { es } from './es'
import { events } from '../data/events'
import { fr } from './fr'
import { it } from './it'
import { legalPages } from '../data/legalPages'
import { pages } from '../data/pages'
import { products } from '../data/products'
import { sellSafely } from '../data/sellSafely'
import { sellPageBlocks } from '../data/sellPageBlocks'

import { createI18n } from 'vue-i18n'

import type { Router } from 'vue-router'
import { paramToCol } from '../utils/config'

export const noAltRoutes = ['/404', '/block-preview', '/blog/']
const allData = {
	pages,
	blogCategories,
	legalPages,
	sellSafely,
	events,
	products,
	blog,
	sellPageBlocks,
}
interface Page {
	slug: string
	[key: string]: any
}

export const messages = {
	en,
	es,
	it,
	fr,
	// nl
}
export type Locale = 'en' | 'es' | 'it' | 'fr' // | 'nl'

export const availableLangs: Locale[] = [
	'en',
	'es',
	'it',
	'fr',
	// 'nl'
]

// global util export

export function langFromPath(path: string): Locale {
	const lang = path.split('/').filter(Boolean).shift() || ''
	return (availableLangs.includes(lang as Locale) ? lang : 'en') as Locale
}

// const locale = localStorage.getItem('locale') || 'en';

export type MessageType = typeof messages
export type AvailableLocales = keyof MessageType
export type MessageSchema = MessageType['en'] | MessageType['es'] | MessageType['it'] | MessageType['fr']
// | MessageType['nl']

const i18nInstance = createI18n<[MessageSchema], AvailableLocales, false>({
	locale: 'en',
	missingWarn: false,
	fallbackWarn: false,
	legacy: false,
	messages,
})

export default i18nInstance
const i18n = i18nInstance.global
const $t = i18n.t

const getCurrentLocale = () => i18nInstance.global.locale.value

export function $tdb(obj?: Record<string, any> & { [key: string]: any }, key?: string, loc?: string) {
	if (!obj || !key) return ''
	loc = loc || i18n.locale.value || 'en'
	return obj[key + loc.toUpperCase()] || obj[`${key}EN`] || obj[key]
}

// Function to find the corresponding page
function findPage(pathSegments: string[]) {
	return pages.find(page => availableLangs.some((lang) => {
		const sourcePath = $tdb(page, 'slug', lang).split('/').filter(Boolean)
		if (!sourcePath.length) return false
		return sourcePath.length === pathSegments.length && sourcePath.every((val: string, index: number) => (val.match(':') && !!pathSegments[index]) || val === pathSegments[index])
	}))
}

export function constructPath(path: Page, lang: Locale, pathSegments: string[]): string {
	return path.split('/').filter(Boolean).map((segment: string, index: number) => {
		if (segment.match(':')) {
			const paramKey = segment.split(':').pop() as keyof typeof paramToCol
			const pathSegment = pathSegments[index].trim()
			const collectionName = paramToCol[paramKey] as keyof typeof allData
			if (!collectionName) return pathSegment
			const item = allData[collectionName].find((obj: any) => availableLangs.some(l => $tdb(obj, 'slug', l) === pathSegment))
			if (!item) console.log(`No item found for ${pathSegment} in ${paramKey}`, path)
			return $tdb(item, 'slug', lang) || pathSegment
		}
		return segment
	}).join('/')
}

export function translatePath(path: string, lang: Locale) {
	if (path === '/') return '/'
	const [pathWithoutHash, hash] = path.split('#')
	const [pathWithoutQuery, query] = pathWithoutHash.split('?')
	const pathSegments: string[] = pathWithoutQuery.split('/').filter(Boolean).filter(p => !availableLangs.includes(p as Locale))
	if (!pathSegments.length) return '/'
	const page = findPage(pathSegments)
	if (!page) throw new Error(`No page found for ${path}, lang ${lang}`)
	const newPath = $tdb(page, 'slug', lang)
	if (!newPath) throw new Error(`No path found for ${path}, lang ${lang}`)
	let p = constructPath(newPath, lang, pathSegments)
	if (hash) p += `#${hash}`
	if (query) p += `?${query}`
	return `/${p}`
}

export function setLangURL(path: string, targetLang: Locale) {
	const noAlt = noAltRoutes.find(r => path.match(r))
	if (noAlt) {
		const root = noAlt.split('/').filter(Boolean).shift()
		return localizer(`/${root}`, targetLang)
	}

	const localPath = translatePath(path, targetLang)
	return localizer(localPath, targetLang)
}

export function setLang(locale: string) {
	i18n.locale.value = locale as Locale
}

export { $t, getCurrentLocale, i18n }

export function localizer(path: string, lang?: string) {
	lang = lang || i18n.locale.value
	if (!path.startsWith('/')) path = `/${path}`
	return lang === 'en' ? path : `/${lang}${path}`
}

export const languages: { locale: Locale, flag: string, name: string }[] = [
	{
		locale: 'en',
		flag: 'GB',
		name: 'English',
	},
	{
		locale: 'it',
		flag: 'IT',
		name: 'Italian',
	},
	{
		locale: 'es',
		flag: 'ES',
		name: 'Spanish',
	},
	{
		locale: 'fr',
		flag: 'FR',
		name: 'French',
	},
	// {
	// 	locale: 'nl',
	// 	flag: 'NL',
	// 	name: 'Dutch',
	// },
]

export function useLangTools(router: Router) {
	const tdb = (obj?: Record<string, any>, key?: string, lang?: string) => {
		const { path } = router.currentRoute.value
		lang = lang || langFromPath(path)
		return $tdb(obj, key, lang)
	}
	const lclz = (path: string) => {
		const lang = langFromPath(router.currentRoute.value.path)
		return localizer(path, lang)
	}

	return { $tdb: tdb, availableLangs, localizer: lclz, langFromPath }
}
