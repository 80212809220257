<script setup lang="ts">
import { Btn, FileUpload } from '@bagelink/vue'
import { useStore } from '@/store'
import { watch } from 'vue';

defineProps<{ uploadIcon: 'image' | 'certificate' }>()

const store = useStore()
const files = defineModel('modelValue', { type: Array, default: () => [] as any[] })

function startUpload() {
	store.isUploading = true
}
watch(files, () => {
	store.isUploading = false
})
</script>

<template>
	<FileUpload accept="image/*,.pdf" v-model="files" multiple @addFileStart="startUpload" f>
		<template #files="{ files }">
			<div
				v-for="(file, i) in files"
				:key="file.id"
				v-lightbox="{ src: file.url, group: 'images' }"
				class="previewName"
			>
				<img height="60" class="preview" :src="file.url" :alt="file.name">
				<p class="act_no-margin">
					{{ file.name }}
				</p>
				<button class="del-img hover" @click.stop="files.splice(i, 1)">
					<img src="../assets/delete.svg" alt="delete">
				</button>
			</div>
		</template>
		<template #placeholder="{ files }">
			<template v-if="!files.length && uploadIcon === 'image'">
				<img class="p-025" src="../assets/Wizard-images.svg" alt="images">
				<p class="m-025 txt-16 balance">
					{{ $t('sell.addingImages') }}
				</p>
			</template>
			<template v-else-if="!files.length">
				<img class="p-025" src="../assets/Wizard-certificate.svg" alt="certificate">
				<p class="m-075 txt-16 balance">
					{{ $t('sell.certificate') }}
				</p>
			</template>
			<Btn class=" pointer-events-none" icon="add" />
		</template>
	</FileUpload>
</template>
