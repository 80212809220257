<script setup lang="ts">import { ref as _ref } from 'vue';

const openPopup = defineModel('visible', { default: false })
const modal = _ref<HTMLElement>()
function closePopup() {
	modal.value?.classList.add('close')
	setTimeout(() => openPopup.value = false, 200)
}
</script>

<template>
	<div v-if="openPopup" ref="modal" class="act_modal-wrap" @click="closePopup">
		<div class="act_modal" @click.stop>
			<div class="close-btn-wrap">
				<button class="close-btn hover" @click="closePopup">
					<img src="../assets/close-black.svg" alt="close">
				</button>
			</div>
			<slot />
		</div>
	</div>
</template>

<style>
.act_modal-wrap {
	width: 100%;
	height: 100%;
	background: rgba(0, 0, 0, 0.7);
	position: fixed;
	top: 0;
	left: 0;
	opacity: 1;
	display: flex;
	z-index: 1000;
	justify-content: center;
	align-items: center;
	transition: all 0.2s ease-in-out;
	animation: fade 0.2s ease-in-out;
	overflow: auto;
}

.close .act_modal {
	transform: scale(0.5);
	opacity: 0;
}

.close .act_modal-wrap {
	opacity: 0;
}
.close-btn-wrap{
	background: white;
	padding: 1rem 0;
	position: sticky;
	top: 0;
	margin-inline: -2rem !important;
	width: calc(100% + 2rem);
	z-index: 9;
}
.close-btn {
	inset-inline-start: 0.75rem;
	background: rgba(255, 255, 255, 0.323);
	border-radius: 100px;
	width: 2rem;
	height: 2rem;
	display: flex;
	align-items: center;
	justify-content: center;
}

@keyframes fade {
	0% {
		opacity: 0;
	}

	100% {
		opacity: 1;
	}
}

.act_modal {
	width: 95%;
	max-width: 600px;
	background: #fff;
	opacity: 1;
	border-radius: 20px;
	border: 1px solid #58BFEB;
	padding: 3rem;
	padding-top: 0rem !important;
	opacity: 1;
	transition: all 0.2s ease-in-out;
	animation: grow 0.2s ease-in-out;
	color: var(--black);
	position: relative;
	max-height: 96vh;
	overflow: auto;
}

.act_modal .white-box {
	padding: 0;
}

@keyframes grow {
	0% {
		transform: scale(0.5);
	}

	100% {
		transform: scale(1);
	}
}
.vip-modal .act_modal {
	max-width: 900px;
	position: relative;
	overflow: hidden;
	min-height: 200px;
	resize: vertical;
	padding: 0;

	}
	.vip-modal .close-btn-wrap{
		margin-inline: 1rem !important;
		width: calc(100% 0 2rem);
	}
	.vip-modal label{
		color: var(--black) !important;
	}

	.vip-modal .white-box {
		overflow: auto;
		max-height: calc(100vh - 80px);
		border-radius: 0;
		padding: 3rem;
		padding-top: 0

		}

		.vip-modal-img {
			height: 100%;
			object-fit: cover;
			object-position: center;
			position: absolute;
			top: 0;
			width: 40%;
			inset-inline-end: 0;
			}
			.act_modal-wrap.vip-modal .white-box{
				width: 60%;
				padding-bottom: 2rem;
				}

				.vip-modal textarea.contact-input {
					min-height: 100px;
				}

@media screen and (max-width: 900px) {
	.act_modal-wrap.vip-modal .white-box.center{
	width: 100%;
	padding-bottom: 0rem;
	padding: 2rem;
}
.act_modal-wrap.vip-modal .white-box{
	width: 100%;
	padding: 2rem;
	padding-top: 0;

}
	.act_modal-wrap {
		overflow: auto;
		/* padding-top: 5rem; */
	}

	.act_modal {
		padding: 3rem 2rem 2rem;
	}

	.vip-modal .white-box {
		padding-inline-end: 0rem;

	}

	.vip-modal-img {
		display: none;
	}
	.close-btn-wrap{
	margin-inline: -1.5rem !important;
}
}
</style>
