import type { App } from 'vue'
import type { Router } from 'vue-router'
import * as Sentry from '@sentry/vue'

export function initSentry(app: App, router: Router, dsn: string) {
	if (import.meta.env.DEV) return
	Sentry.init({
		app,
		dsn,
		integrations: [
			Sentry.browserTracingIntegration({ router }),
			Sentry.replayIntegration(),
		],
		tracesSampleRate: 1.0,
		tracePropagationTargets: ['localhost'],
		replaysSessionSampleRate: 0.1,
		replaysOnErrorSampleRate: 1.0,
	})
}
